import React, { useEffect, useState } from 'react'
import { PiePagina } from '../PiePagina'
import { Header } from '../Header'
import { Grid } from '@mui/material'
import MapView from './MapView'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import { getoEquipos } from '../../apis'
import { SelectorLocalizadores } from './SeletcorLocalizadores';

export const MapContext = () => {
	// const [equiposFiltrados, setEquiposFiltrados] = useState([]);
	// const [equipos, setEquipos] = useState([]);
	const [loaded, setLoaded] = useState(false);

	const param = useParams();
	// useEffect(() => {
	// 	cargarEdiciones();
	// }, [])


	// const cargarEdiciones = async () => {

	// 	try {
	// 		const response = await getoEquipos();
	// 		setEquipos(response.data[0].equipos);
	// 		console.log("Localizadores cargados:", response.data[0].equipos);
	// 	} catch (error) {
	// 		console.error("Error al cargar localizadores:", error);
	// 	}
	// };


	return (
		<div className="fade-in">
			<Header></Header>
			<Grid container xs={12} gap={1} direction="row" justifyContent="center" alignItems="start" style={{ backgroundColor: '#2E2E2E', paddingBottom: '1em' }}>
				<Grid container xs={12} direction="row" justifyContent="center" alignItems="center">
					<h1>Seguimiento</h1>
				</Grid>
				{/* <Grid container xs={12} sm={10} md={10} lg={3.5} xl={3} direction="row" justifyContent="center" alignItems="center">
					<SelectorLocalizadores equipos={equipos} equiposFiltrados={equiposFiltrados} setEquiposFiltrados={setEquiposFiltrados}></SelectorLocalizadores>
				</Grid> */}
				<Grid container xs={12} justifyContent="center" alignItems="center">
					<MapView ></MapView>
				</Grid>
			</Grid>
			<PiePagina></PiePagina>
		</div>
	)
}
