import React from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

export const TablaActividadesVal = () => {
    return (
        <div style={{ overflow: 'auto' }}>
            <TableContainer component={Paper} style={{ backgroundColor: '#C9C9C9', borderRadius: '15px' }} elevation={12}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow style={{ backgroundColor: '#EAC474' }}>
                            <TableCell><strong style={{ color: '#2E2E2E' }}>Data</strong></TableCell>
                            <TableCell><strong style={{ color: '#2E2E2E' }}>Hora</strong></TableCell>
                            <TableCell><strong style={{ color: '#2E2E2E' }}>Activitats</strong></TableCell>
                            <TableCell><strong style={{ color: '#2E2E2E' }}>Lloc</strong></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell align="left">04.03.25</TableCell>
                            <TableCell align="left"> </TableCell>
                            <TableCell align="left">Obertura de les inscripcions</TableCell>
                            <TableCell align="left">www.transbetxi.com</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell align="left">21.03.25</TableCell>
                            <TableCell align="left">22:00H</TableCell>
                            <TableCell align="left">Participants pre-briefing</TableCell>
                            <TableCell align="left">Auditori Municipal de Betxí</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell align="left">04.04.25</TableCell>
                            <TableCell align="left">19-21:00</TableCell>
                            <TableCell align="left">Inscripció i pagament</TableCell>
                            <TableCell align="left">MALANIMA</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell align="left">05.04.25</TableCell>
                            <TableCell align="left">19-21:00</TableCell>
                            <TableCell align="left">Pagament i tancament d'inscripcions</TableCell>
                            <TableCell align="left">AGORA</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell align="left">07.04.25</TableCell>
                            <TableCell align="left">22:00 H.</TableCell>
                            <TableCell align="left">Publicació de la llista d'inscrits</TableCell>
                            <TableCell align="left">www.transbetxi.com</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell align="left">23.04.25</TableCell>
                            <TableCell align="left">22:00 H.</TableCell>
                            <TableCell align="left">Briefing Participants Inicial</TableCell>
                            <TableCell align="left">Auditori Municipal de Betxí</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell align="left">25.04.25</TableCell>
                            <TableCell align="left">18:00 H.</TableCell>
                            <TableCell align="left">Control Tècnic i Administratiu, primer vehicle</TableCell>
                            <TableCell align="left">L´AGORA RURALNOSTRA</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell align="left">25.04.25</TableCell>
                            <TableCell align="left">20:00 H.</TableCell>
                            <TableCell align="left">Termini de presentació dels vehicles participants</TableCell>
                            <TableCell align="left">L´AGORA RURALNOSTRA</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell align="left">26.04.25</TableCell>
                            <TableCell align="left">08:30 H.</TableCell>
                            <TableCell align="left">Termini de presentació dels participants</TableCell>
                            <TableCell align="left">FRONTÓ MUNICIPAL</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell align="left">26.04.25</TableCell>
                            <TableCell align="left">09:00 H.</TableCell>
                            <TableCell align="left">Trasllat de vehicles neutralitzats de PT1 a TC1</TableCell>
                            <TableCell align="left"></TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell align="left">26.04.25</TableCell>
                            <TableCell align="left">09:30 H.</TableCell>
                            <TableCell align="left">Tram cronometrat TC1, primer vehicle</TableCell>
                            <TableCell align="left"></TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell align="left">26.04.25</TableCell>
                            <TableCell align="left">10:30 H.</TableCell>
                            <TableCell align="left">Tram cronometrat TC2, primer vehicle</TableCell>
                            <TableCell align="left"></TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell align="left">26.04.25</TableCell>
                            <TableCell align="left">11:30 H.</TableCell>
                            <TableCell align="left">Trasllat de vehicles neutralitzats de TC2 a PT2</TableCell>
                            <TableCell align="left"></TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell align="left">06.04.24</TableCell>
                            <TableCell align="left">12:00 H.</TableCell>
                            <TableCell align="left"><strong style={{ color: '#2E2E2E' }}>1ª ETAPA FINAL</strong></TableCell>
                            <TableCell align="left"></TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell align="left">26.04.25</TableCell>
                            <TableCell align="left">15:00 H.</TableCell>
                            <TableCell align="left">Temps límit per a la presentació dels participants per poder transferir neutralitzat de PT2 a TC3</TableCell>
                            <TableCell align="left">FRONTÓ MUNICIPAL</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell align="left">26.04.25</TableCell>
                            <TableCell align="left">15:30 H.</TableCell>
                            <TableCell align="left">Tram cronometrat TC3, primer vehicle</TableCell>
                            <TableCell align="left"></TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell align="left">26.04.25</TableCell>
                            <TableCell align="left">16:30 H.</TableCell>
                            <TableCell align="left">Tram cronometrat TC4, primer vehicle</TableCell>
                            <TableCell align="left"></TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell align="left">26.04.25</TableCell>
                            <TableCell align="left">17:30 H.</TableCell>
                            <TableCell align="left">Trasllat de vehicles neutralitzats del TC4 al PT3</TableCell>
                            <TableCell align="left">FRONTÓ MUNICIPAL</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell align="left">26.04.25</TableCell>
                            <TableCell align="left">18:00 H.</TableCell>
                            <TableCell align="left"><strong style={{ color: '#2E2E2E' }}>2ª ETAPA FINAL</strong></TableCell>
                            <TableCell align="left">L´AGORA RURALNOSTRA</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell align="left">27.04.25</TableCell>
                            <TableCell align="left">08:30 H.</TableCell>
                            <TableCell align="left">Temps de presentació dels participants</TableCell>
                            <TableCell align="left"></TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell align="left">27.04.25</TableCell>
                            <TableCell align="left">09:00 H.</TableCell>
                            <TableCell align="left">Termini de presentació dels participants</TableCell>
                            <TableCell align="left"></TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell align="left">27.04.25</TableCell>
                            <TableCell align="left">09:30 H.</TableCell>
                            <TableCell align="left">Trasllat de vehicles neutralitzats del PT4 al TC5</TableCell>
                            <TableCell align="left"></TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell align="left">27.04.25</TableCell>
                            <TableCell align="left">10:00 H.</TableCell>
                            <TableCell align="left">Tram cronometrat TC5, primer vehicle</TableCell>
                            <TableCell align="left"></TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell align="left">27.04.25</TableCell>
                            <TableCell align="left">11:00 H.</TableCell>
                            <TableCell align="left">Tram cronometrat TC6, primer vehicle</TableCell>
                            <TableCell align="left"></TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell align="left">27.04.25</TableCell>
                            <TableCell align="left">12:00 H.</TableCell>
                            <TableCell align="left">Trasllat de vehicles neutralitzats del TC6 al PT5</TableCell>
                            <TableCell align="left"></TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell align="left">27.04.25</TableCell>
                            <TableCell align="left">12:30 H.</TableCell>
                            <TableCell align="left"><strong style={{ color: '#2E2E2E' }}>3ª ETAPA FINAL</strong></TableCell>
                            <TableCell align="left"></TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell align="left">27.04.25</TableCell>
                            <TableCell align="left">13:00 H.</TableCell>
                            <TableCell align="left">Termini de presentació de reclamacions i comprovacions tècniques</TableCell>
                            <TableCell align="left">FRONTÓ MUNICIPAL</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell align="left">27.04.25</TableCell>
                            <TableCell align="left">18:00 H.</TableCell>
                            <TableCell align="left">Publicació dels resultats</TableCell>
                            <TableCell align="left">www.transbetxi.com</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell align="left">27.04.25</TableCell>
                            <TableCell align="left">18:30 H.</TableCell>
                            <TableCell align="left">Lliurament de trofeus</TableCell>
                            <TableCell align="left">FRONTÓ MUNICIPAL</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}
