import React, { useEffect, useState } from 'react';
import { TiemposCard } from './TiemposCard';
import { Button, Collapse, Grid } from '@mui/material';
import { LeyendaPenalizaciones } from '../../components/LeyendaPenalizaciones';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

export const Tiempos = ({ tiempos, edicion, tramo, categoria }) => {

    const [first, setFirst] = useState([]);
    const [iterado, setIterado] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [mostrarPen, setMostrarPen] = useState(false);
    useEffect(() => {
        const tiemposPorEdicion = tiempos.filter((tiempo) => tiempo.nombreEdicion === edicion);
        let tramosDeEdicion = null;
        if (tiemposPorEdicion[0]) {
            console.log("tramosDeEdicion", tiemposPorEdicion[0].tramos);

            tramosDeEdicion = tiemposPorEdicion[0].tramos;

            tramosDeEdicion.forEach(tram => {
                if (tram.codigotramo === 9999995) {
                    // Orden especial para el tramo general
                    tram.resultados.sort((a, b) => {
                        // Primero por tramos completados (desc)
                        const cmp = (b.tramosCompletados || 0) - (a.tramosCompletados || 0);
                        if (cmp === 0) {
                            // Si igual, ordenar por tiempo (asc)
                            return a.tiempo - b.tiempo;
                        }
                        return cmp;
                    });
                } else {
                    // Orden normal por tiempo
                    tram.resultados.sort((a, b) => a.tiempo - b.tiempo);
                }
            });
            const tramoBuscado = tramosDeEdicion.find(tram => tram.codigotramo === tramo.codigotramo);

            if (tramoBuscado) {
                setFirst(tramoBuscado.resultados);
                setIterado(tramoBuscado.resultados);
            } else {
                //si no hay resultado se setea a 0 para que no colapse
                setFirst([]);
                setIterado([]);
            }

        } else {
            //en caso de no encontrar resultados seteara a 0 para que no colapse
            setIterado([]);
            setFirst([]);
        }



        setLoaded(true);
    }, [tramo, tiempos, edicion]);

    useEffect(() => {
        if (first.length > 0 && first != undefined) {
            let temp = [...first];

            if (categoria === 5) {
                temp = temp.filter(tmp => tmp.codigocategoria === 2 || tmp.codigocategoria === 1);
            } else if (categoria === 4) {
                temp = temp.filter(tmp => tmp.codigocategoria === 2 || tmp.codigocategoria === 1 || tmp.codigocategoria === 3);
            } else {
                temp = temp.filter(tmp => tmp.codigocategoria === categoria);
            }
            setIterado(temp);
        }
    }, [categoria, first]);

    return (
        <div style={{ minWidth: '100%' }}>
            {(loaded === false) && (
                <div className='flex-container spinner'>
                    <div className='loading-spinner'></div>
                </div>
            )}

            <Grid
                container
                direction='row'
                justifyContent="center"
                alignItems="center"
                xs={12}
                gap={1}
                sx={{ mt: 1 }}
            >
                <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    xs={12}
                >

                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        xs={12}
                        gap={{ xs: 0, md: 0.5 }}
                        style={{ borderTopLeftRadius: 5, borderTopRightRadius: 5, paddingBottom: 5, paddingTop: 5 }}
                        className='tiempos-card-pair2'
                    >
                        <div className='posicion '>x</div>
                        <div className='dorsal bx blue'>x</div>
                        &nbsp;
                        <div className='nombre-equipo '>TIEMPO CONFIRMADO</div>
                        &nbsp;
                        <div className={'tiempo confirmado'}>
                            HH:MM:SS
                        </div>
                        <div className='tramos-completados ' style={{ marginLeft: 5 }}>
                            <strong style={{ color: '#EAC474' }}>(*)</strong>
                        </div>
                    </Grid>

                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        xs={12}
                        gap={{ xs: 0, md: 0.5 }}
                        className='tiempos-card-pair2'
                        style={{ paddingBottom: 5, paddingTop: 5 }}
                    >
                        <div className='posicion '>x</div>
                        <div className='dorsal bx blue'>x</div>
                        &nbsp;
                        <div className='nombre-equipo '>TIEMPO PROVISIONAL</div>
                        &nbsp;
                        <div className={'tiempo provisional'}>
                            HH:MM:SS
                        </div>
                        <div className='tramos-completados ' style={{ marginLeft: 5 }}>
                            <strong style={{ color: '#EAC474' }}>(*)</strong>
                        </div>
                    </Grid>

                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        xs={12}
                        gap={{ xs: 0, md: 0.5 }}
                        style={{ borderBottomLeftRadius: 5, borderBottomRightRadius: 5, paddingBottom: 5, paddingTop: 5 }}
                        className='tiempos-card-pair2 descalificado'
                    >
                        <div className='posicion '>x</div>
                        <div className='dorsal bx blue'>x</div>
                        &nbsp;
                        <div className='nombre-equipo '>DESCALIFICADO</div>
                        &nbsp;
                        <div className={'tiempo provisional'}>
                            HH:MM:SS
                        </div>
                        <div className='tramos-completados ' style={{ marginLeft: 5 }}>
                            <strong style={{ color: '#EAC474' }}>(*)</strong>
                        </div>
                    </Grid>

                    <Grid item xs={12} sx={{ mt: 1, mb: 0.5 }}>
                        <small style={{ color: '#EAC474' }}>(*): Total tramos finalizados.</small>
                    </Grid>
                </Grid>

                <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    xs={12}
                >
                    <Button
                        style={{ backgroundColor: '#eac474', color: 'black' }}
                        variant='contained'
                        onClick={() => { mostrarPen ? setMostrarPen(false) : setMostrarPen(true) }}
                        fullWidth
                    >
                        Mostrar penalizaciones
                        {mostrarPen ? <KeyboardArrowUpIcon sx={{ mt: -0.5 }} /> : <KeyboardArrowDownIcon sx={{ mt: -0.5 }} />}
                    </Button>

                    <Collapse fullWidth in={mostrarPen} sx={{ backgroundColor: 'gray', minWidth: '100%' }}>
                        <LeyendaPenalizaciones></LeyendaPenalizaciones>
                    </Collapse>

                </Grid>

            </Grid>

            {(loaded === true) && (
                <div>

                    {/* Tiempos de la edicion {first[0].nombreEdicion} */}
                    <div className='tiempos-container'>
                        <div className='tramo-title'>{tramo.nombre} {tramo.descripcion}</div>
                        {((iterado).length > 0) && (
                            iterado.map((resultado, index) => (
                                <TiemposCard resultado={resultado} index={index} key={index} />
                            ))
                        )}
                        {((iterado).length <= 0) && (
                            <div className='flex-center'>
                                <h3> Aun no hay Resultados disponibles</h3>
                                <br></br>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};
