import React, { useState } from 'react'
import { Header } from '../../components/Header';
import { PiePagina } from '../../components/PiePagina';
import Grid from '@mui/material/Grid';
import inscripcion from '../../media/icons/inscribirse.png';
import { FormularioInscripciones } from './FormularioInscripciones';

export const InscripcionesEs = () => {

    const [mostrarForm, setMostrarForm] = useState(false);
    return (
        <div className="fade-in">
            <Header></Header>
            <Grid container xs={12} md={12} direction="column" justifyContent="flex-start" alignItems="center" style={{ backgroundColor: '#2E2E2E', minHeight: '70vh' }}>
                <Grid container xs={12} md={12} direction="row" justifyContent="center" alignItems="center">
                    <h1>INSCRIPCIONES</h1>
                </Grid>

                <Grid container xs={10} md={6} direction="column" justifyContent="flex-start" alignItems="flex-start" style={{ textAlign: 'left', color: '#EAC474' }}>


                    <p>El procedimiento correcto de una inscripción consta de las siguientes partes y deben seguirse en el mismo orden:</p>
                    <ol>
                        <li>Inscripción por parte del equipo del piloto, copiloto y máquina en el impreso que la página www.transbetxi.com dispone para poder efectuarla.</li>
                        <li>Al inscribirse aparecerá el botón: "validar y descargar", se descargara y se enviara por correo electronico un documento que habra que llevar firmado el dia de el pago.</li>
                        <li>Comprobación de la inscripción para el listado oficial de inscritos.</li>
                    </ol>
                    <p>Se tiene que llevar debidamente rellenada y firmada el dia que se efectúa el pago de la inscripción</p>
                    <strong>PILOTO:</strong>
                    <ol>
                        <li>COPIA IMPRESA DE LA INSCRIPCIÓN REALIZADA EN LA PÁGINA WEB.</li>
                        <li>1 FOTOCOPIA Y ORIGINAL DE LA LICENCIA FEDERATIVA (parte delantera y reverso).</li>
                        <li>1 FOTOCOPIA Y ORIGINAL DEL DOCUMENTO NACIONAL DE IDENTIDAD (parte delantera y reverso).</li>
                        <li>1 FOTOCOPIA Y ORIGINAL DEL PERMISO DE CONDUCIR: B1 (parte delantera y reverso).</li>
                    </ol>
                    <strong>COPILOTO:</strong>
                    <ol>
                        <li>COPIA IMPRESA DE LA INSCRIPCIÓN REALIZADA EN LA PÁGINA WEB.</li>
                        <li>1 FOTOCOPIA Y ORIGINAL DE LA LICENCIA FEDERATIVA (parte delantera y reverso).</li>
                        <li>1 FOTOCOPIA Y ORIGINAL DEL DOCUMENTO NACIONAL DE IDENTIDAD (parte delantera y reverso).</li>
                        <li>1 FOTOCOPIA Y ORIGINAL DEL PERMISO DE CONDUCIR: B1 (parte delantera y reverso) O RECONOCIMIENTO MÉDICO EN VIGOR DEL AÑO EN CURSO.</li>
                    </ol>

                </Grid>

                <Grid container xs={10} md={10} direction="column" justifyContent="center" alignItems="center">
                    <h2>Formulario federativo</h2>
                    <img src={inscripcion} alt="Icon" />
                    <a href="https://fmcv.es/wp-content/uploads/2023/10/seguro-1dia.pdf" target="_blank" style={{ color: '#EAC474' }}> Descargar aquí </a>
                </Grid>

                <Grid container xs={10} md={10} direction="column" justifyContent="center" alignItems="center" style={{ marginTop: '5em' }}>
                    <h2>Formulario de inscripción: Piloto y Copiloto</h2>
                </Grid>


                <Grid container xs={7} md={3} direction="column" justifyContent="center" alignItems="center" style={{ textAlign: 'left', color: '#EAC474' }}>
                    {mostrarForm === true && (
                        <FormularioInscripciones setMostrarForm={setMostrarForm} />
                    )}
                    <div className="button-adm" style={{ color: 'black' }} onClick={() => setMostrarForm(true)}>
                        Realizar Inscripción
                    </div>
                </Grid>
            </Grid>
            <PiePagina></PiePagina>
        </div>
    )
}
