import React from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

export const TablaActividadesEs = () => {
    return (
        <div style={{ overflow: 'auto' }}>
            <TableContainer component={Paper} style={{ backgroundColor: '#C9C9C9', borderRadius: '15px', overflow: 'auto' }} elevation={12}>
                <Table aria-label="simple table" style={{ minWidth: '100%' }}>
                    <TableHead>
                        <TableRow style={{ backgroundColor: '#EAC474' }}>
                            <TableCell><strong style={{ color: '#2E2E2E' }}>Fecha</strong></TableCell>
                            <TableCell><strong style={{ color: '#2E2E2E' }}>Hora</strong></TableCell>
                            <TableCell><strong style={{ color: '#2E2E2E' }}>Actividad</strong></TableCell>
                            <TableCell><strong style={{ color: '#2E2E2E' }}>Sitio</strong></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell align="left">04.03.25</TableCell>
                            <TableCell align="left"> </TableCell>
                            <TableCell align="left">Apertura de inscripciones</TableCell>
                            <TableCell align="left">www.transbetxi.com</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell align="left">21.03.25</TableCell>
                            <TableCell align="left">22:00H</TableCell>
                            <TableCell align="left">Pre-briefing Participantes</TableCell>
                            <TableCell align="left">Auditori Municipal de Betxí</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell align="left">04.04.25</TableCell>
                            <TableCell align="left">19‐21:00</TableCell>
                            <TableCell align="left">Inscripciones y pago</TableCell>
                            <TableCell align="left">MALANIMA</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell align="left">05.04.25</TableCell>
                            <TableCell align="left">19-21:00</TableCell>
                            <TableCell align="left">Pago y cierre de inscripciones </TableCell>
                            <TableCell align="left">AGORA</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell align="left">07.04.25</TableCell>
                            <TableCell align="left">22:00 H.</TableCell>
                            <TableCell align="left">Publicación de la Lista de inscritos</TableCell>
                            <TableCell align="left">www.transbetxi.com</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell align="left">23.04.25</TableCell>
                            <TableCell align="left">22:00 H.</TableCell>
                            <TableCell align="left">Briefing Participantes Inicial</TableCell>
                            <TableCell align="left">Auditori Municipal de Betxí</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell align="left">25.04.25</TableCell>
                            <TableCell align="left">18:00 H.</TableCell>
                            <TableCell align="left">Control Técnico y Administrativo, primer vehículo</TableCell>
                            <TableCell align="left">EL AGORA RURALNOSTRA</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell align="left">25.04.25</TableCell>
                            <TableCell align="left">20:00 H.</TableCell>
                            <TableCell align="left">Hora límite para la presentación de los vehículos participantes</TableCell>
                            <TableCell align="left">EL AGORA RURALNOSTRA</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell align="left">26.04.25</TableCell>
                            <TableCell align="left">08:30 H.</TableCell>
                            <TableCell align="left">Hora límite para la presentación de los participantes</TableCell>
                            <TableCell align="left">FRONTON MUNICIPAL</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell align="left">26.04.25</TableCell>
                            <TableCell align="left">09:00 H.</TableCell>
                            <TableCell align="left">Traslado de los vehículos neutralizados desde el PT1 al TC1</TableCell>
                            <TableCell align="left">FRONTON MUNICIPAL</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell align="left">26.04.25</TableCell>
                            <TableCell align="left">09:30 H.</TableCell>
                            <TableCell align="left">Tramo Cronometrado TC1, primer vehículo</TableCell>
                            <TableCell align="left"></TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell align="left">26.04.25</TableCell>
                            <TableCell align="left">10:30 H.</TableCell>
                            <TableCell align="left">Tramo Cronometrado TC2, primer vehículo</TableCell>
                            <TableCell align="left"></TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell align="left">26.04.25</TableCell>
                            <TableCell align="left">11:30 H.</TableCell>
                            <TableCell align="left">Traslado de los vehículos neutralizados desde TC2 al PT2</TableCell>
                            <TableCell align="left"></TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell align="left">26.04.25</TableCell>
                            <TableCell align="left">12:00 H.</TableCell>
                            <TableCell align="left"><strong style={{ color: '#2E2E2E' }}>FINAL 1ª ETAPA</strong></TableCell>
                            <TableCell align="left"></TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell align="left">26.04.25</TableCell>
                            <TableCell align="left">15:00 H.</TableCell>
                            <TableCell align="left">Hora límite para la presentación de los participantes para poder realizar el traslado neutralizados del PT2 al TC3</TableCell>
                            <TableCell align="left">FRONTÓN MUNICIPAL</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell align="left">26.04.25</TableCell>
                            <TableCell align="left">15:30 H.</TableCell>
                            <TableCell align="left">Tramo Cronometrado TC3, primer vehículo</TableCell>
                            <TableCell align="left"></TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell align="left">26.04.25</TableCell>
                            <TableCell align="left">16:30 H.</TableCell>
                            <TableCell align="left">Tramo Cronometrado TC4, primer vehículo</TableCell>
                            <TableCell align="left"></TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell align="left">26.04.25</TableCell>
                            <TableCell align="left">17:30 H.</TableCell>
                            <TableCell align="left">Traslado de los vehículos neutralizados desde TC4 al PT3</TableCell>
                            <TableCell align="left">EL AGORA RURALNOSTRA</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell align="left">26.04.25</TableCell>
                            <TableCell align="left">18:00 H.</TableCell>
                            <TableCell align="left"><strong style={{ color: '#2E2E2E' }}>FINAL 2ª ETAPA</strong></TableCell>
                            <TableCell align="left">EL AGORA RURALNOSTRA</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell align="left">27.04.25</TableCell>
                            <TableCell align="left">08:30 H.</TableCell>
                            <TableCell align="left">Hora presentación de los participantes</TableCell>
                            <TableCell align="left"></TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell align="left">27.04.25</TableCell>
                            <TableCell align="left">09:00 H.</TableCell>
                            <TableCell align="left">Hora límite para la presentación de los participantes</TableCell>
                            <TableCell align="left"></TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell align="left">27.04.25</TableCell>
                            <TableCell align="left">09:30 H.</TableCell>
                            <TableCell align="left">Traslado de los vehículos neutralizados desde el PT4 al TC5</TableCell>
                            <TableCell align="left"></TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell align="left">27.04.25</TableCell>
                            <TableCell align="left">10:00 H.</TableCell>
                            <TableCell align="left">Tramo Cronometrado TC5, primer vehículo</TableCell>
                            <TableCell align="left"></TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell align="left">27.04.25</TableCell>
                            <TableCell align="left">11:00 H.</TableCell>
                            <TableCell align="left">Tramo Cronometrado TC6, primer vehículo</TableCell>
                            <TableCell align="left"></TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell align="left">27.04.25</TableCell>
                            <TableCell align="left">12:00 H.</TableCell>
                            <TableCell align="left">Traslado de los vehículos neutralizados desde TC6 al PT5</TableCell>
                            <TableCell align="left"></TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell align="left">27.04.25</TableCell>
                            <TableCell align="left">12:30 H.</TableCell>
                            <TableCell align="left"><strong style={{ color: '#2E2E2E' }}>FINAL 3ª ETAPA</strong></TableCell>
                            <TableCell align="left">FRONTÓN MUNICIPAL</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell align="left">27.04.25</TableCell>
                            <TableCell align="left">13:30 H.</TableCell>
                            <TableCell align="left">Hora límite para presentar las reclamaciones y verificaciones técnicas</TableCell>
                            <TableCell align="left">FRONTÓN MUNICIPAL</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell align="left">27.04.25</TableCell>
                            <TableCell align="left">18:00 H.</TableCell>
                            <TableCell align="left">Publicación de los resultados</TableCell>
                            <TableCell align="left">www.transbetxi.com</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell align="left">27.04.25</TableCell>
                            <TableCell align="left">18:30 H.</TableCell>
                            <TableCell align="left">Entrega de trofeos</TableCell>
                            <TableCell align="left">FRONTÓN MUNICIPAL</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}
