import React from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

export const TablaActividadesEn = () => {
    return (
        <div style={{ overflow: 'auto'}}>
            <TableContainer component={Paper} style={{ backgroundColor: '#C9C9C9', borderRadius: '15px' }} elevation={12}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow style={{ backgroundColor: '#EAC474' }}>
                            <TableCell><strong style={{ color: '#2E2E2E' }}>Date</strong></TableCell>
                            <TableCell><strong style={{ color: '#2E2E2E' }}>Hour</strong></TableCell>
                            <TableCell><strong style={{ color: '#2E2E2E' }}>Activity</strong></TableCell>
                            <TableCell><strong style={{ color: '#2E2E2E' }}>Place</strong></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell align="left">04.03.25</TableCell>
                            <TableCell align="left"> </TableCell>
                            <TableCell align="left">Opening of registrations</TableCell>
                            <TableCell align="left">www.transbetxi.com</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell align="left">21.03.25</TableCell>
                            <TableCell align="left">22:00H</TableCell>
                            <TableCell align="left">Pre-briefing Participants</TableCell>
                            <TableCell align="left">Auditori Municipal de Betxí</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell align="left">04.04.25</TableCell>
                            <TableCell align="left">19-21:00</TableCell>
                            <TableCell align="left">Registration and payment</TableCell>
                            <TableCell align="left">MALANIMA</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell align="left">05.04.25</TableCell>
                            <TableCell align="left">19-21:00</TableCell>
                            <TableCell align="left">Payment and closing of registrations</TableCell>
                            <TableCell align="left">AGORA</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell align="left">07.04.25</TableCell>
                            <TableCell align="left">22:00 H.</TableCell>
                            <TableCell align="left">Publication of the List of Entrants</TableCell>
                            <TableCell align="left">www.transbetxi.com</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell align="left">23.04.25</TableCell>
                            <TableCell align="left">22:00 H.</TableCell>
                            <TableCell align="left">Briefing Participants Inicial</TableCell>
                            <TableCell align="left">Auditori Municipal de Betxí</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell align="left">25.04.25</TableCell>
                            <TableCell align="left">18:00 H.</TableCell>
                            <TableCell align="left">Technical and Administrative Control, first vehicle</TableCell>
                            <TableCell align="left">L´AGORA RURALNOSTRA</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell align="left">25.04.25</TableCell>
                            <TableCell align="left">20:00 H.</TableCell>
                            <TableCell align="left">Time limit for the presentation of participating vehicles</TableCell>
                            <TableCell align="left">L´AGORA RURALNOSTRA</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell align="left">26.04.25</TableCell>
                            <TableCell align="left">08:30 H.</TableCell>
                            <TableCell align="left">Time limit for the presentation of participants</TableCell>
                            <TableCell align="left">FRONTÓ MUNICIPAL</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell align="left">26.04.25</TableCell>
                            <TableCell align="left">09:00 H.</TableCell>
                            <TableCell align="left">Transfer of neutralized vehicles from PT1 to TC1</TableCell>
                            <TableCell align="left"></TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell align="left">26.04.25</TableCell>
                            <TableCell align="left">09:30 H.</TableCell>
                            <TableCell align="left">Timed section TC1, first vehicle</TableCell>
                            <TableCell align="left"></TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell align="left">26.04.25</TableCell>
                            <TableCell align="left">10:30 H.</TableCell>
                            <TableCell align="left">Timed section TC2, first vehicle</TableCell>
                            <TableCell align="left"></TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell align="left">26.04.25</TableCell>
                            <TableCell align="left">11:30 H.</TableCell>
                            <TableCell align="left">Transfer of neutralized vehicles from TC2 to PT2</TableCell>
                            <TableCell align="left"></TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell align="left">26.04.25</TableCell>
                            <TableCell align="left">12:00 H.</TableCell>
                            <TableCell align="left"><strong style={{ color: '#2E2E2E' }}>FINAL 1ST STAGE</strong></TableCell>
                            <TableCell align="left"></TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell align="left">26.04.25</TableCell>
                            <TableCell align="left">15:00 H.</TableCell>
                            <TableCell align="left">Time limit for the presentation of the participants to be able to transfer neutralized from PT2 to TC3</TableCell>
                            <TableCell align="left">FRONTÓ MUNICIPAL</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell align="left">26.04.25</TableCell>
                            <TableCell align="left">15:30 H.</TableCell>
                            <TableCell align="left">Timed section TC3, first vehicle</TableCell>
                            <TableCell align="left"></TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell align="left">26.04.25</TableCell>
                            <TableCell align="left">16:30 H.</TableCell>
                            <TableCell align="left">Timed section TC4, first vehicle</TableCell>
                            <TableCell align="left"></TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell align="left">26.04.25</TableCell>
                            <TableCell align="left">17:30 H.</TableCell>
                            <TableCell align="left">Transfer of neutralized vehicles from TC4 to PT3</TableCell>
                            <TableCell align="left">FRONTÓ MUNICIPAL</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell align="left">26.04.25</TableCell>
                            <TableCell align="left">18:00 H.</TableCell>
                            <TableCell align="left"><strong style={{ color: '#2E2E2E' }}>FINAL 2nd STAGE</strong></TableCell>
                            <TableCell align="left">FRONTÓ MUNICIPAL</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell align="left">27.04.25</TableCell>
                            <TableCell align="left">08:30 H.</TableCell>
                            <TableCell align="left">Presentation time of the participants</TableCell>
                            <TableCell align="left"></TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell align="left">27.04.25</TableCell>
                            <TableCell align="left">09:00 H.</TableCell>
                            <TableCell align="left">Time limit for the presentation of participants</TableCell>
                            <TableCell align="left"></TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell align="left">27.04.25</TableCell>
                            <TableCell align="left">09:30 H.</TableCell>
                            <TableCell align="left">Transfer of neutralized vehicles from PT4 to TC5</TableCell>
                            <TableCell align="left"></TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell align="left">27.04.25</TableCell>
                            <TableCell align="left">10:30 H.</TableCell>
                            <TableCell align="left">Timed section TC5, first vehicle</TableCell>
                            <TableCell align="left"></TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell align="left">27.04.25</TableCell>
                            <TableCell align="left">11:30 H.</TableCell>
                            <TableCell align="left">Timed section TC6, first vehicle</TableCell>
                            <TableCell align="left"></TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell align="left">27.04.25</TableCell>
                            <TableCell align="left">12:30 H.</TableCell>
                            <TableCell align="left">Transfer of neutralized vehicles from TC6 to PT5</TableCell>
                            <TableCell align="left"></TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell align="left">27.04.25</TableCell>
                            <TableCell align="left">13:00 H.</TableCell>
                            <TableCell align="left"><strong style={{ color: '#2E2E2E' }}>FINAL 3RD STAGE</strong></TableCell>
                            <TableCell align="left"></TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                            <TableCell> </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell align="left">27.04.25</TableCell>
                            <TableCell align="left">13:00 H.</TableCell>
                            <TableCell align="left">Time limit for submitting claims and technical checks</TableCell>
                            <TableCell align="left">FRONTÓ MUNICIPAL</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell align="left">27.04.25</TableCell>
                            <TableCell align="left">18:00 H.</TableCell>
                            <TableCell align="left">Publication of the results</TableCell>
                            <TableCell align="left">www.transbetxi.com</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell align="left">27.04.25</TableCell>
                            <TableCell align="left">18:30 H.</TableCell>
                            <TableCell align="left">Delivery of trophies</TableCell>
                            <TableCell align="left">FRONTÓ MUNICIPAL</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}
