import axios from "axios";
import { getCookie } from "./utils/cookies";
const baseUrl = 'https://www.transbetxi.com/WS/'

export const getoLocalizadores = async () => {
    try {
        const response = await axios.get(baseUrl + 'localizadores.php?', {
            params: { token: getCookie('PHPSESSID') },
        });

        if (response.data.success) {
            // Procesar la respuesta y actualizar las opciones si es necesario
            return response.data;
        } else {
            console.log('No se pudo verificar el token');
            return null;
        }
    } catch (error) {
        console.error('Error al verificar el token:', error);
        return null;
    }
};

export const getoEquipos = async () => {
    try {
        const response = await axios.get(baseUrl + 'equiposLocalizador.php?', {
            headers: {
                'code': 'pass23sd2aASED6',
            }
        });

        if (response.data.success) {
            // Procesar la respuesta y actualizar las opciones si es necesario
            return response.data;
        } else {
            console.log('No se pudo verificar el token');
            return null;
        }
    } catch (error) {
        console.error('Error al verificar el token:', error);
        return null;
    }
};

export const getoCntrEquipos = async () => {
    try {
        const response = await axios.get(baseUrl + 'localizadoresConectadosws.php?', {
            params: { token: getCookie('PHPSESSID') },

        });

        if (response) {
            // Procesar la respuesta y actualizar las opciones si es necesario
            return response.data;
        } else {
            console.log('No se pudo verificar el token');
            return null;
        }
    } catch (error) {
        console.error('Error al verificar el token:', error);
        return null;
    }
};