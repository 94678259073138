import { SystemSecurityUpdate } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import { Outlet, Navigate, useLocation } from 'react-router-dom';
import { getCookie } from '../utils/cookies';
import axios from 'axios';
import { Header } from '../components/Header';

const PrivateRoute = ({ element: Element, ...rest }) => {
    // Obtener el token almacenado en localStorage
    const [isTokenValid, setIsTokenValid] = useState(null);
    const location = useLocation();
    // Verificar si el token está presente y es válidod
    useEffect(() => {
        const token = getCookie('PHPSESSID');
        // Asegúrate de que 'PHPSESSID' sea el nombre correcto de la cookie que contiene el token
    
        if (token) {
          axios.post('https://www.transbetxi.com/WS/VerificarToken.php', {
            token: token,
          })
          .then(response => {
            console.log("response", response)
            if (response.data.success) {
              setIsTokenValid(true);
              console.log("verificado ")
    
            } else {
              setIsTokenValid(false);
              localStorage.clear();
    
              console.log("te tiene que tirar")
            }
          })
          .catch(error => {
            console.error('Error al verificar el token:', error);
            setIsTokenValid(false);
            localStorage.clear();
    
          });
        } else {
          setIsTokenValid(false);
          localStorage.clear();
    
        }
      }, [location]);
    
      if (isTokenValid === null) {
        // Aquí puedes devolver un componente de carga si lo deseas
        return <div><Header/></div>;
      }
    
      // Verificar si el token está presente y es válido
      return isTokenValid ? <Outlet /> : <Navigate to="/login" />;
    };
export default PrivateRoute;
