import React from 'react';
import { HeaderVal } from '../../components/HeaderVal';
import { PiePagina } from '../../components/PiePagina';
import Grid from '@mui/material/Grid';
import { TablaActividadesVal } from '../tablaActividades/TablaActividadesVal';


export const ActividadesVal = () => {
  return (
    <div className="fade-in">
      <HeaderVal></HeaderVal>

      <Grid container xs={12} md={12} direction="row" justifyContent="center" alignItems="center" style={{ backgroundColor: '#2E2E2E', paddingBottom: '1em' }}>
        <h1>ACTIVITATS</h1>
      </Grid>

      <Grid container xs={12} md={12} direction="row" justifyContent="center" alignItems="center" style={{ backgroundColor: '#2E2E2E', paddingBottom: '1em' }}>
        <Grid container xs={11.5} md={11} direction="row" justifyContent="center" alignItems="center" style={{ backgroundColor: '#2E2E2E' }}>
          <TablaActividadesVal></TablaActividadesVal>
        </Grid>
      </Grid>





      <PiePagina></PiePagina>
    </div>
  )
}
