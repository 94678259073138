import React from 'react'
import { Header } from '../../components/Header';
import { PiePagina } from '../../components/PiePagina';
import Grid from '@mui/material/Grid';
import { TablaActividadesEs } from '../tablaActividades/TablaActividadesEs';


export const ActividadesEs = () => {


  return (
    <div className="fade-in">
      <Header></Header>
      <Grid container xs={12} md={12} direction="row" justifyContent="center" alignItems="center" style={{ backgroundColor: '#2E2E2E', paddingBottom: '1em' }}>
        <h1>ACTIVIDADES</h1>
      </Grid>


      <Grid container xs={12} md={12} direction="row" justifyContent="center" alignItems="center" style={{ backgroundColor: '#2E2E2E', paddingBottom: '1em' }}>
        <Grid container xs={11.5} md={11} direction="row" justifyContent="center" alignItems="center" style={{ backgroundColor: '#2E2E2E' }}>
          <TablaActividadesEs></TablaActividadesEs>
        </Grid>
      </Grid>


      <PiePagina></PiePagina>
    </div>
  )
}
