import React, { useEffect, useState } from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { MainMenuView } from '../pages/MainMenuView';
import { Header } from '../components/Header';
import { TiemposEquipo } from '../pages/TiemposEquipo';
import { TiemposGeneral } from '../pages/TiemposGeneral';
import { TiemposTramos } from '../pages/TiemposTramos';
import { ClasificacionesF } from '../pages/ClasificacionesF';
import { Login } from '../pages/Login';
// import { PanelPrincipal } from '../components/panel-admin/PanelPrincipal';
import { TiemposWp } from '../wordpress-views/TiemposWp';
import { EquiposWp } from '../wordpress-views/EquiposWp';
import { Home } from '../pages/Home';
import { Reglamentos } from '../pages/Reglamentos';
import { ReglamentoPrueba } from '../pages/ReglamentoPrueba';
import { ReglamentoTecnico } from '../pages/ReglamentoTecnico';
import { Inscripciones } from '../pages/Inscripciones';
import { MapasTramos } from '../pages/MapasTramos';
import { Actividades } from '../pages/Actividades';
import { Patrocinadores } from '../pages/Patrocinadores';
import { Patrocinadoresadm } from '../components/panel-admin/patrocinadores/Patrocinadoresadm';

import { PanelPrincipal } from '../components/panel-admin/PanelPrincipal';
import { Historia } from '../pages/Historia';
import { GestionTIempoEd } from '../components/panel-admin/gestion-tiempos/GestionTIempoEd';
import { TiempoTramo } from '../components/panel-admin/gestion-tiempos/TiempoTramo';
import axios from 'axios';
import PrivateRoute from './PrivateRoute';
import { DondeComer } from '../pages/DondeComer';
import { Proximamente } from '../pages/Proximamente';
import { Edicion } from '../components/panel-admin/Edicion';
import { Tramos } from '../components/panel-admin/tramos/Tramos';
import { Equipos } from '../components/panel-admin/equipos/Equipos';
import { DetallesEquipo } from '../wordpress-views/DetallesEquipo';

import { CookiesEs } from '../components/cookies/CookiesEs';
import { CookiesEn } from '../components/cookies/CookiesEn';
import { CookiesVal } from '../components/cookies/CookiesVal';
import { CookiesGer } from '../components/cookies/CookiesGer';
import { useMyContext } from '../MyProvider '; //Variable global del idioma
import { PoliticaCookies } from '../pages/PoliticaCookies';
import { Notificaciones } from '../components/notificaciones/Notificaciones';
import { Advertencias } from '../components/panel-admin/advertencias/Advertencias';
import { Mapas } from '../components/panel-admin/mapa/Mapas';
import { MapView } from '../components/seguimiento/MapView';
import { EnDirecto } from '../pages/EnDirecto';
import { MapContext } from '../components/seguimiento/MapContext';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { ControlEquipos } from '../components/seguimiento/ControlEquipos';


const Router = () => {

	// // colores de los componentes del MUI
	// const theme = createTheme({
	// 	palette: {
	// 		primary: {
	// 			main: '#EAC474',
	// 		},
	// 		secondary: {
	// 			main: '#402e20',
	// 		},
	// 	},

	// });

	//global
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const { miVariableGlobal, setMiVariableGlobal } = useMyContext();



	const contadorVisitas = () => {

		axios.put('https://www.transbetxi.com/WS/visitas.php')
			.then(function (response) {
				// manejar respuesta exitosa
				// console.log(response.data, "dads")
			})
			.catch(function (error) {
				// manejar error
				console.log(error);
			})
			.finally(function () {
				// siempre sera executado
			});
		// console.log("asdasd");
	};

	useEffect(() => {
		// Limpiar el intervalo al desmontar el componente
		contadorVisitas();

	}, [])

	return (
		// <ThemeProvider theme={theme}>

			<div className='back-main'>
				<BrowserRouter>
					<Routes>
						<Route path='/' element={<Home />} ></Route>

						{/* landing_page */}
						<Route path="/home" element={<Home />} ></Route>
						<Route path='/mapas' element={<MapasTramos />}></Route>
						<Route path='/actividades' element={<Actividades />}></Route>
						<Route path='/donde-comer' element={<DondeComer />}></Route>
						<Route path='/en-directo' element={<EnDirecto />}></Route>


						<Route path="/equipos" element={<EquiposWp />}></Route>

						<Route path='/patrocinadores' element={<Patrocinadores />}></Route>

						<Route path="/tiempos" element={<TiemposWp />}></Route>


						{/* <Route path="/inscripciones" element={<EquiposWp />}></Route> */}
						<Route path="/inscripciones" element={<Inscripciones />}></Route>
						<Route path="/reglamentos" element={<Reglamentos />}></Route>
						<Route path="/historia" element={<Historia />}></Route>

						<Route path="/reglamento-de-la-prueba" element={<ReglamentoPrueba />}></Route>
						<Route path="/reglamento-tecnico" element={<ReglamentoTecnico />}></Route>
						<Route path="/politica-cookies" element={<PoliticaCookies />}></Route>

						{/* appviews */}
						<Route element={<PrivateRoute />}>

							<Route path="/tiemposs" element={<TiemposWp />}></Route>
							<Route path="/equiposs" element={<EquiposWp />}></Route>

							<Route path="/panel-admin" element={<PanelPrincipal />} ></Route>
							<Route path="/tiempos-equipos" element={<TiemposEquipo />}></Route>
							<Route path="/tiempos-general" element={<TiemposGeneral />}></Route>
							<Route path="/tiempos-tramos" element={<TiemposTramos />}></Route>
							{/* <Route path="/clasificaciones" element={<ClasificacionesF />}></Route> */}
							<Route path="/gestion-tiempo/:id" element={<GestionTIempoEd />}></Route>
							<Route path="/tiempo-tramo/:id/:edicion/:nombretramo" element={<TiempoTramo />}></Route>
							<Route path="/edicion/:edicion" element={<Edicion />}></Route>
							<Route path="/equipos/:edicion" element={<Equipos />}></Route>
							<Route path="/tramos/:edicion/:nombredicion" element={<Tramos />}></Route>
							<Route path="/advertencias/:edicion" element={<Advertencias />}></Route>
							<Route path="/mapa/:edicion" element={<Mapas />}></Route>
							<Route path="/patrocinadoresadm/:edicion" element={<Patrocinadoresadm />}></Route>
							<Route path="/detalles-equipo/:codigoequipo" element={<DetallesEquipo />}></Route>
							<Route path='/mapatest' element={<MapContext />}></Route>
							<Route path='/controlEquipos' element={<ControlEquipos />}></Route>


						</Route>

						{/* <Route path="/panel-principal-administrador" element={<PanelPrincipal />}></Route> */}
						<Route path="/login" element={<Login username={username} setUsername={setUsername} password={password} setPassword={setPassword} />}></Route>
						<Route path="*" element={<Navigate to="/" replace />} />

					</Routes>
				</BrowserRouter>
				{(miVariableGlobal == 1) && (
					<CookiesEs></CookiesEs>
				)}

				{(miVariableGlobal == 2) && (
					<CookiesVal></CookiesVal>
				)}

				{(miVariableGlobal == 3) && (
					<CookiesEn></CookiesEn>
				)}

				{(miVariableGlobal == 4) && (
					<CookiesGer></CookiesGer>
				)}
				<Notificaciones></Notificaciones>




			</div>
		// </ThemeProvider >

	)
}

export default Router