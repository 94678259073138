import React, { useState } from 'react'
import { HeaderEn } from '../../components/HeaderEn';
import { PiePagina } from '../../components/PiePagina';
import Grid from '@mui/material/Grid';
import inscripcion from '../../media/icons/inscribirse.png';
import { FormularioInscripciones } from './FormularioInscripciones';

export const InscripcionesEn = () => {
    const [mostrarForm, setMostrarForm] = useState(false);

    return (
        <div className="fade-in">
            <HeaderEn></HeaderEn>
            <Grid container xs={12} md={12} direction="column" justifyContent="flex-start" alignItems="center" style={{ backgroundColor: '#2E2E2E', minHeight: '70vh' }}>
                <Grid container xs={12} md={12} direction="row" justifyContent="center" alignItems="center">
                    <h1>REGISTRATIONS</h1>
                </Grid>

                <Grid container xs={10} md={6} direction="column" justifyContent="flex-start" alignItems="flex-start" style={{ textAlign: 'left', color: '#EAC474' }} >


                    <p>The correct registration procedure consists of the following parts and must be followed in the same order:</p>
                    <ol>
                        <li>Registration by the pilot, co-pilot and machine team on the form that the website www.transbetxi.com has to complete it.</li>
                        <li>When you register, a "validate and download" button will appear. A document will be downloaded and emailed to you, which you must bring signed on the day of payment.</li>
                        <li>Verification of registration for the official list of registrants.</li>
                        <p>It must be duly completed and signed on the day the registration payment is made.</p>
                        <strong>DRIVER:</strong>
                        <ol>
                            <li>PRINT COPY OF THE REGISTRATION MADE ON THE WEBSITE.</li>
                            <li>1 PHOTOCOPY AND ORIGINAL OF THE FEDERATIVE LICENSE (front and back).</li>
                            <li>1 PHOTOCOPY AND ORIGINAL OF THE NATIONAL IDENTITY DOCUMENT (front and back).</li>
                            <li>1 PHOTOCOPY AND ORIGINAL OF THE DRIVING LICENSE: B1 (front and back).</li>
                        </ol>
                        <strong>CO-DRIVER:</strong>
                        <ol>
                            <li>PRINT COPY OF THE REGISTRATION MADE ON THE WEBSITE.</li>
                            <li>1 PHOTOCOPY AND ORIGINAL OF THE FEDERATIVE LICENSE (front and back).</li>
                            <li>1 PHOTOCOPY AND ORIGINAL OF THE NATIONAL IDENTITY DOCUMENT (front and back).</li>
                            <li>1 PHOTOCOPY AND ORIGINAL OF THE DRIVING LICENSE: B1 (front and back) OR MEDICAL EXAMINATION IN VALID FOR THE CURRENT YEAR.</li>
                        </ol>
                    </ol>
                </Grid>

                <Grid container xs={10} md={10} direction="column" justifyContent="center" alignItems="center">
                    <h2>Federation form</h2>
                    <img src={inscripcion} alt="Icon" />
                    <a href="https://fmcv.es/wp-content/uploads/2023/10/seguro-1dia.pdf" target="_blank" style={{ color: '#EAC474' }}> Download here </a>
                </Grid>
                <Grid container xs={7} md={3} direction="column" justifyContent="center" alignItems="center" style={{ textAlign: 'left', color: '#EAC474' }}>
                    {mostrarForm === true && (
                        <FormularioInscripciones setMostrarForm={setMostrarForm} />
                    )}
                    <div className="button-adm" style={{ color: 'black' }} onClick={() => setMostrarForm(true)}>
                        Make Registration
                    </div>
                </Grid>

            </Grid>
            <PiePagina></PiePagina>
        </div>
    )
}
