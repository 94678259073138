import React, { useState } from 'react'
import { HeaderVal } from '../../components/HeaderVal';
import { PiePagina } from '../../components/PiePagina';
import Grid from '@mui/material/Grid';
import inscripcion from '../../media/icons/inscribirse.png';
import { FormularioInscripciones } from './FormularioInscripciones';

export const InscripcionesVal = () => {
    const [mostrarForm, setMostrarForm] = useState(false);

    return (
        <div className="fade-in">
            <HeaderVal></HeaderVal>

            <Grid container xs={12} md={12} direction="column" justifyContent="flex-start" alignItems="center" style={{ backgroundColor: '#2E2E2E', minHeight: '70vh' }}>
                <Grid container xs={12} md={12} direction="row" justifyContent="center" alignItems="center">
                    <h1>INSCRIPCIONS</h1>
                </Grid>

                <Grid container xs={10} md={6} direction="column" justifyContent="flex-start" alignItems="flex-start" style={{ textAlign: 'left', color: '#EAC474' }}>


                    <p>El procediment correcte d’una inscripció consta de les següents parts i cal seguir-les en el mateix ordre:</p>
                    <ol>
                        <li>Inscripció per part de l’equip del pilot, copilot i màquina a l’imprès que la pàgina www.transbetxi.com disposa per tal de poder efectuar-la.</li>
                        <li>En inscriure's apareixerà el botó: "validar i descarregar", es descarregarà i s'enviarà per correu electrònic un document que caldrà portar signat el dia del pagament.</li>
                        <li>Comprovació de la inscripció per al llistat oficial d’inscrits.</li>
                        <p>S'ha de portar degudament emplenada i signada el dia que s'efectua el pagament de la inscripció</p>
                        <strong>PILOT:</strong>
                        <ol>
                            <li>CÒPIA IMPRESA DE LA INSCRIPCIÓ REALITZADA A LA PÀGINA WEB.</li>
                            <li>1 FOTOCÒPIA I ORIGINAL DE LA LLICÈNCIA FEDERATIVA (envers i revers).</li>
                            <li>1 FOTOCÒPIA I ORIGINAL DEL DOCUMENT NACIONAL D’IDENTITAT (envers i revers).</li>
                            <li>1 FOTOCÒPIA I ORIGINAL DEL PERMÍS DE CONDUIR: B1 (envers i revers).</li>
                        </ol>
                        <strong>COPILOT:</strong>
                        <ol>
                            <li>CÒPIA IMPRESA DE LA INSCRIPCIÓ REALITZADA A LA PÀGINA WEB.</li>
                            <li>1 FOTOCÒPIA I ORIGINAL DE LA LLICÈNCIA FEDERATIVA (envers i revers).</li>
                            <li>1 FOTOCÒPIA I ORIGINAL DEL DOCUMENT NACIONAL D’IDENTITAT (envers i revers).</li>
                            <li>1 FOTOCÒPIA I ORIGINAL DEL PERMÍS DE CONDUIR: B1 (envers i revers) O RECONEIXEMENT MÈDIC EN VIGOR DE L’ANY EN CURS.</li>
                        </ol>
                    </ol>

                </Grid>

                <Grid container xs={10} md={10} direction="column" justifyContent="center" alignItems="center">
                    <h2>Formulari federatiu</h2>
                    <img src={inscripcion} alt="Icon" />
                    <a href="https://fmcv.es/wp-content/uploads/2023/10/seguro-1dia.pdf" target="_blank" style={{ color: '#EAC474' }}> Descarregar ací </a>
                </Grid>
                <Grid container xs={7} md={3} direction="column" justifyContent="center" alignItems="center" style={{ textAlign: 'left', color: '#EAC474' }}>
                    {mostrarForm === true && (
                        <FormularioInscripciones setMostrarForm={setMostrarForm} />
                    )}
                    <div className="button-adm" style={{ color: 'black' }} onClick={() => setMostrarForm(true)}>
                        Realitzar Inscripció
                    </div>
                </Grid>
            </Grid>


            <PiePagina></PiePagina>

        </div>
    )
}
