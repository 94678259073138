import { Alert, Checkbox, Divider, FormControlLabel, Grid, MenuItem, Select, TextField, Typography } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react';
import Swal from 'sweetalert2';
import axios from 'axios';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { Pdf } from '../../components/Pdf';
// import { saveAs } from 'file-saver';
import { pdf } from '@react-pdf/renderer';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';


export const FormularioInscripciones = ({ setMostrarForm }) => {

    const [detallesEquipo, setDetallesEquipo] = useState({
        nombre_piloto: null,
        apellidos_piloto: null,
        dni_piloto: null,
        direccion_piloto: null,
        correo_piloto: null,
        telf_piloto: null,
        fecha_piloto: null,

        nombre_copiloto: null,
        apellidos_copiloto: null,
        dni_copiloto: null,
        direccion_copiloto: null,
        correo_copiloto: null,
        telf_copiloto: null,
        fecha_copiloto: null,
        telefono_contacto: null,

        nombre_equipo: null,
        tipo_maquina: 'Clasica'
    })

    const [inscrito, setinscrito] = useState(false);
    const [open, setOpen] = useState(true);
    const [tratoDatos, setTratoDatos] = useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

    const handleClose = () => {
        setOpen(false);
    };
    const [alert, setAlert] = useState(false);

    const fileInputRef = useRef(null);



    const handleOnChange = event => {
        const { name, value } = event.target;

        setDetallesEquipo(prevState => ({
            ...prevState,
            [name]: value
        }));
    };
    const handleDownload = async () => {
        const blob = await pdf(<Pdf detallesEquipo={detallesEquipo} />).toBlob()
        // console.log(blob, 'inscripccio_transbetxí_2025.pdf')

        enviarCorreo(blob);

    }
    const enviarPreInscripcion = () => {
        // console.log("hola", detallesEquipo);


        axios.post('https://www.transbetxi.com/WS/preinscripciones.php', detallesEquipo)
            .then(function (response) {
                console.log(response)
                if (response.data.success) {

                    detallesEquipo.idInscrip = response.data.idPreincriocion;
                    // setMostrarForm(false);
                    setinscrito(true);
                    // enviarCorreo(detallesEquipo);
                } else if (response.data.success == false) {
                    setAlert(response.data.error);
                }

            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const enviarCorreo = async (pdf) => {
        try {
            // Crear un objeto FormData
            const formData = new FormData();
            formData.append('data', JSON.stringify(detallesEquipo));
            formData.append('file[]', pdf);

            const response = await axios.post('https://transbetxi.com/WS/enviarInscripcion.php', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data', // Indicar que se usa multipart/form-data
                }
            });

            // console.log(response.data.success);
            if (response.data.success) {
                handleClose();
                setMostrarForm(false);
                Swal.fire({
                    title: "Validado!",
                    text: "Se ha descargado la inscripcion en su dispositivo y se la ha enviado una copia por correo a ambos integrantes del equipo",
                    icon: "success",
                    showConfirmButton: true,
                    timer: 10000,
                });
            } else {
                handleClose();
                setMostrarForm(false);
                Swal.fire({
                    title: "Error al validar la inscripcion",
                    text: "Ha ocurrido un error al validar la inscripcion, contacte con la organizacion para solucionarlo.",
                    icon: "error",
                    showConfirmButton: false,
                    timer: 2500,
                });
            }

        } catch (error) {
            console.error('Error al enviar la tarea:', error);
            // return null;
        }
    };


    return (
        <div>

            <div className='detalles-equipo'>

                <Grid container xs={11} md={10} direction="column" justifyContent="center" alignItems="center">

                    <Dialog
                        // fullScreen={fullScreen}
                        // fullScreen
                        open={open}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >

                        <DialogContent style={{ backgroundColor: '#C9C9C9' }}>

                            <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                                xs={12}
                                className='tarjeta_formulario'
                            >
                                <Grid
                                    container
                                    direction="column"
                                    justifyContent="center"
                                    alignItems="center"
                                    xs={12}
                                >
                                    <Typography variant='h4'>Inscripción 2025</Typography>
                                    <Divider style={{ borderColor: 'black', minWidth: '70%', marginBottom: '1em' }} />
                                    <Typography variant='h6' gutterBottom>Datos Piloto</Typography>
                                </Grid>

                                <Grid
                                    container
                                    direction="column"
                                    justifyContent="center"
                                    alignItems="center"
                                    xs={12}
                                    gap={2}
                                >
                                    <Grid
                                        container
                                        direction="column"
                                        justifyContent="center"
                                        alignItems="start"
                                        xs={12}
                                    >
                                        <Typography variant='subtitle1'>Nombre Piloto:</Typography>
                                        <TextField
                                            fullWidth
                                            onChange={handleOnChange}
                                            name='nombre_piloto'
                                            value={detallesEquipo.nombre_piloto}
                                            id="outlined-basic"
                                            variant="outlined"
                                            className='field'
                                            inputProps={{ maxLength: 64 }}
                                        />
                                    </Grid>

                                    <Grid
                                        container
                                        direction="column"
                                        justifyContent="center"
                                        alignItems="start"
                                        xs={12}
                                    >
                                        <Typography variant='subtitle1'>Apellidos Piloto:</Typography>

                                        <TextField
                                            fullWidth
                                            onChange={handleOnChange}
                                            name='apellidos_piloto'
                                            value={detallesEquipo.apellidos_piloto}
                                            id="outlined-basic"
                                            variant="outlined"
                                            className='field'
                                            inputProps={{ maxLength: 64 }}
                                        />
                                    </Grid>


                                    <Grid
                                        container
                                        direction="column"
                                        justifyContent="center"
                                        alignItems="start"
                                        xs={12}
                                    >
                                        <Typography variant='subtitle1'>DNI Piloto:</Typography>

                                        <TextField
                                            fullWidth
                                            onChange={handleOnChange}
                                            name='dni_piloto'
                                            value={detallesEquipo.dni_piloto}
                                            id="outlined-basic"
                                            variant="outlined"
                                            className='field'
                                        />
                                    </Grid>

                                    <Grid
                                        container
                                        direction="column"
                                        justifyContent="center"
                                        alignItems="start"
                                        xs={12}
                                    >
                                        <Typography variant='subtitle1'>Dirección:</Typography>

                                        <TextField
                                            fullWidth
                                            onChange={handleOnChange}
                                            name='direccion_piloto'
                                            value={detallesEquipo.direccion_piloto}
                                            id="outlined-basic"
                                            variant="outlined"
                                            className='field'
                                        />
                                    </Grid>

                                    <Grid
                                        container
                                        direction="column"
                                        justifyContent="center"
                                        alignItems="start"
                                        xs={12}
                                    >
                                        <Typography variant='subtitle1'>Correo electronico:</Typography>

                                        <TextField
                                            fullWidth
                                            onChange={handleOnChange}
                                            name='correo_piloto'
                                            value={detallesEquipo.correo_piloto}
                                            id="outlined-basic"
                                            variant="outlined"
                                            className='field'
                                        />
                                    </Grid>


                                    <Grid
                                        container
                                        direction="column"
                                        justifyContent="center"
                                        alignItems="start"
                                        xs={12}
                                    >
                                        <Typography variant='subtitle1'>Telefono:</Typography>

                                        <TextField
                                            fullWidth
                                            onChange={handleOnChange}
                                            name='telf_piloto'
                                            value={detallesEquipo.telf_piloto}
                                            id="outlined-basic"
                                            variant="outlined"
                                            className='field'
                                            type='numeric'
                                        />
                                    </Grid>

                                    <Grid
                                        container
                                        direction="column"
                                        justifyContent="center"
                                        alignItems="start"
                                        xs={12}
                                    >
                                        <Typography variant='subtitle1'>Fecha de nacimiento:</Typography>

                                        <TextField
                                            fullWidth
                                            onChange={handleOnChange}
                                            name="fecha_piloto"
                                            value={detallesEquipo.fecha_piloto}
                                            id="outlined-basic"
                                            variant="outlined"
                                            className='field'
                                            type='date'
                                        />
                                    </Grid>
                                </Grid>

                                <Divider style={{ borderColor: 'black', minWidth: '90%', margin: '2em' }} />

                                <Grid
                                    container
                                    direction="column"
                                    justifyContent="center"
                                    alignItems="center"
                                    xs={12}
                                >
                                    <Typography variant='h6' gutterBottom>Datos Copiloto</Typography>
                                </Grid>

                                <Grid
                                    container
                                    direction="column"
                                    justifyContent="center"
                                    alignItems="center"
                                    xs={12}
                                >

                                    <Grid
                                        container
                                        direction="column"
                                        justifyContent="center"
                                        alignItems="start"
                                        xs={12}
                                    >
                                        <Typography variant='subtitle1'>Nombre Copiloto:</Typography>

                                        <TextField
                                            fullWidth
                                            onChange={handleOnChange}
                                            name='nombre_copiloto'
                                            value={detallesEquipo.nombre_copiloto}
                                            id="outlined-basic"
                                            variant="outlined"
                                            className='field'
                                            inputProps={{ maxLength: 64 }} />
                                    </Grid>

                                    <Grid
                                        container
                                        direction="column"
                                        justifyContent="center"
                                        alignItems="start"
                                        xs={12}
                                    >
                                        <Typography variant='subtitle1'>Apellidos Copiloto:</Typography>

                                        <TextField
                                            fullWidth
                                            onChange={handleOnChange}
                                            name='apellidos_copiloto'
                                            value={detallesEquipo.apellidos_copiloto}
                                            id="outlined-basic"
                                            variant="outlined"
                                            className='field'
                                            inputProps={{ maxLength: 64 }}
                                        />
                                    </Grid>

                                    <Grid
                                        container
                                        direction="column"
                                        justifyContent="center"
                                        alignItems="start"
                                        xs={12}
                                    >
                                        <Typography variant='subtitle1'>DNI Copiloto:</Typography>

                                        <TextField
                                            fullWidth
                                            onChange={handleOnChange}
                                            name='dni_copiloto'
                                            value={detallesEquipo.dni_copiloto}
                                            id="outlined-basic"
                                            variant="outlined"
                                            className='field'
                                        />
                                    </Grid>


                                    <Grid
                                        container
                                        direction="column"
                                        justifyContent="center"
                                        alignItems="start"
                                        xs={12}
                                    >
                                        <Typography variant='subtitle1'>Direccion Copiloto:</Typography>

                                        <TextField
                                            fullWidth
                                            onChange={handleOnChange}
                                            name='direccion_copiloto'
                                            value={detallesEquipo.direccion_copiloto}
                                            id="outlined-basic"
                                            variant="outlined"
                                            className='field'
                                        />
                                    </Grid>

                                    <Grid
                                        container
                                        direction="column"
                                        justifyContent="center"
                                        alignItems="start"
                                        xs={12}
                                    >
                                        <Typography variant='subtitle1'>Correo Copiloto:</Typography>

                                        <TextField
                                            fullWidth
                                            onChange={handleOnChange}
                                            name='correo_copiloto'
                                            value={detallesEquipo.correo_copiloto}
                                            id="outlined-basic"
                                            variant="outlined"
                                            className='field'
                                        />
                                    </Grid>

                                    <Grid
                                        container
                                        direction="column"
                                        justifyContent="center"
                                        alignItems="start"
                                        xs={12}
                                    >
                                        <Typography variant='subtitle1'>Telefono:</Typography>

                                        <TextField
                                            fullWidth
                                            onChange={handleOnChange}
                                            name='telf_copiloto'
                                            value={detallesEquipo.telf_copiloto}
                                            id="outlined-basic"
                                            variant="outlined"
                                            className='field'
                                            type='numeric'

                                        />
                                    </Grid>

                                    <Grid
                                        container
                                        direction="column"
                                        justifyContent="center"
                                        alignItems="start"
                                        xs={12}
                                    >
                                        <Typography variant='subtitle1'>Fecha de nacimiento:</Typography>

                                        <TextField
                                            fullWidth
                                            onChange={handleOnChange}
                                            name="fecha_copiloto"
                                            value={detallesEquipo.fecha_copiloto}
                                            id="outlined-basic"
                                            variant="outlined"
                                            className='field'
                                            type='date'

                                        />
                                    </Grid>
                                </Grid>

                                <Divider style={{ borderColor: 'black', minWidth: '90%', margin: '2em' }} />

                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="center"
                                    xs={12}
                                >
                                    <Typography variant='h6' gutterBottom>Datos Equipo</Typography>

                                </Grid>

                                <Grid
                                    container
                                    direction="column"
                                    justifyContent="center"
                                    alignItems="start"
                                    xs={12}
                                >
                                    <Typography variant='subtitle1'>Nombre del equipo:</Typography>

                                    <TextField
                                        fullWidth
                                        onChange={handleOnChange}
                                        name='nombre_equipo'
                                        value={detallesEquipo.nombre_equipo}
                                        id="outlined-basic"
                                        variant="outlined"
                                        className='field'
                                        inputProps={{ maxLength: 64 }}
                                    />
                                </Grid>

                                <Grid
                                    container
                                    direction="column"
                                    justifyContent="center"
                                    alignItems="start"
                                    xs={12}
                                >
                                    <Typography variant='subtitle1'>Telefono de Urgencia:</Typography>

                                    <TextField
                                        fullWidth
                                        onChange={handleOnChange}
                                        name='telefono_urgencia'
                                        value={detallesEquipo.telefono_urgencia}
                                        id="outlined-basic"
                                        variant="outlined"
                                        className='field'
                                        type='numeric'

                                        inputProps={{ maxLength: 64 }}
                                    />
                                </Grid>

                                <Grid
                                    container
                                    direction="column"
                                    justifyContent="center"
                                    alignItems="start"
                                    xs={12}
                                >
                                    <Typography variant='subtitle1'>Telefono de Ubicacion Whattsapp:</Typography>

                                    <TextField
                                        fullWidth
                                        onChange={handleOnChange}
                                        name='telefono_ubicacion'
                                        value={detallesEquipo.telefono_ubicacion}
                                        id="outlined-basic"
                                        variant="outlined"
                                        className='field'
                                        type='numeric'

                                        inputProps={{ maxLength: 64 }}
                                    />

                                    <Typography variant='body2' gutterBottom>
                                        Este teléfono puede ser el de el piloto o el copiloto,
                                        es obligatorio llevarlo el día del rally.
                                    </Typography>
                                </Grid>

                                <Grid
                                    container
                                    direction="column"
                                    justifyContent="center"
                                    alignItems="start"
                                    xs={12}
                                >
                                    <Typography variant='subtitle1'>Tipo de maquina:</Typography>

                                    <Select
                                        fullWidth
                                        name='tipo_maquina'
                                        labelId="demo-select-small-label"
                                        id="demo-select-small"
                                        value={detallesEquipo.tipo_maquina}
                                        label="Age"
                                        onChange={handleOnChange}
                                        className='field'
                                    >

                                        <MenuItem value={"Clasica"}>Clasica</MenuItem>
                                        <MenuItem value={"Prototipo"}>Prototipo</MenuItem>
                                        <MenuItem value={"Agria"}>Agria</MenuItem>
                                    </Select>

                                </Grid>
                            </Grid>

                            <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                                xs={12}
                            >
                                <Grid
                                    container
                                    direction="column"
                                    justifyContent="center"
                                    alignItems="center"
                                    xs={11}
                                >
                                    <FormControlLabel
                                        required
                                        control={<Checkbox />}
                                        label="Ambos componentes del equipo aceptamos que los datos rellenados en este formulario sean tratados para darnos de alta como Equipo"
                                        onClick={() => tratoDatos ? setTratoDatos(false) : () => setTratoDatos(true)}
                                    />
                                </Grid>

                            </Grid>

                            <Divider style={{ borderColor: 'black', minWidth: '90%', margin: '2em' }} />

                            <Grid
                                container
                                direction="column"
                                justifyContent="center"
                                alignItems="center"
                                xs={12}
                            >
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="center"
                                    xs={12}
                                    gap={2}
                                >
                                    <Grid item xs={5}>
                                        <>
                                            {!tratoDatos ? (
                                                <Button
                                                    style={{ backgroundColor: '#eac474', color: '#333333' }}
                                                    fullWidth
                                                    size="large"
                                                    variant="contained"
                                                    onClick={() => enviarPreInscripcion()}
                                                >
                                                    Inscribirse
                                                </Button>
                                            ) : (
                                                <Button
                                                    fullWidth
                                                    style={{ backgroundColor: '#eac474', color: '#333333' }}
                                                    size="large"
                                                    variant="contained"
                                                    onClick={() => setAlert("Es obligatorio Aceptar el Trato de datos")}
                                                >
                                                    Inscribirse
                                                </Button>
                                            )}
                                        </>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <Button fullWidth style={{ color: '#333333', borderColor: '#333333' }} size='large' variant='outlined' onClick={() => setMostrarForm(false)}>Cancelar</Button>
                                    </Grid>
                                </Grid>

                                {alert != false && (
                                    <div className="overlay" onClick={() => setAlert(false)}>
                                        <Alert variant="filled" severity="error">
                                            {alert}
                                        </Alert>
                                    </div>
                                )}
                                {(inscrito === true && (
                                    <Dialog
                                        open={open}
                                        onClose={handleClose}
                                        aria-labelledby="alert-dialog-title"
                                        aria-describedby="alert-dialog-description"
                                    >
                                        <DialogContent style={{ padding: '2em', textAlign: 'center' }}>
                                            <Typography variant='h4' gutterBottom>Validar y descargar</Typography>
                                            <PDFDownloadLink document={<Pdf detallesEquipo={detallesEquipo} />} filename='inscripcion_transbetxi_2025.pdf'>
                                                {({ loading }) => (

                                                    <Button
                                                        style={{ backgroundColor: '#eac474', color: '#333333', padding: '1em' }}
                                                        fullWidth
                                                        size="large"
                                                        variant="contained"
                                                        onClick={loading ? null : handleDownload}
                                                    >
                                                        {loading ? "Verificando..." : 'Validar y Descargar Inscripcion'}
                                                    </Button>
                                                )}
                                            </PDFDownloadLink>
                                        </DialogContent>
                                    </Dialog>

                                ))}
                            </Grid>


                        </DialogContent>
                    </Dialog>

                </Grid>
            </div>


        </div >
    )
}
