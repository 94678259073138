import React, { useRef, useEffect, useState } from "react";
import { getoEquipos, getoLocalizadores } from "../../apis";
import { Button, Grid } from "@mui/material";
import { SelectorLocalizadores } from "./SeletcorLocalizadores";

const WEBSOCKET_URL = "wss://websockets.transbetxi.com:8443";
const GOOGLE_MAPS_API_KEY = "AIzaSyD2mCQsTLIh0dpT5PB9U7mSCvlAHzjwDlk";

export const MapView = () => {

    const [equiposFiltrados, setEquiposFiltrados] = useState([]);
    const [equipos, setEquipos] = useState([]);
    const googleMapRef = useRef(null);
    const googleMap = useRef(null);
    const markerRef = useRef({});
    const polylineRef = useRef({});
    const speedRef = useRef({});
    const [mapLoaded, setMapLoaded] = useState(false);
    const [localizadores, setLocalizadores] = useState([]);
    const [localizadoresFiltrados, setLocalizadoresFiltrados] = useState([]);
    const [allLocalizadores, setAllLocalizadores] = useState([]);

    const cargarEdiciones = async () => {

        try {
            const response = await getoEquipos();
            setEquipos(response.data[0].equipos);
            console.log("Localizadores cargados:", response.data[0].equipos);
        } catch (error) {
            console.error("Error al cargar localizadores:", error);
        }
    };
    useEffect(() => {
        cargarEdiciones();
    }, [])
    const cargarLocalizadores = async () => {
        try {
            const response = await getoLocalizadores();
            setLocalizadores(response.data);
            console.log("Localizadores cargados:", response.data);
        } catch (error) {
            console.error("Error al cargar localizadores:", error);
        }
    };

    useEffect(() => {
        console.log("🔄 Cargando Google Maps...");
        cargarLocalizadores();

        const loadGoogleMap = () => {
            if (window.google && !googleMap.current) {
                console.log("✅ Google Maps cargado correctamente.");
                googleMap.current = new window.google.maps.Map(googleMapRef.current, {
                    center: { lat: 39.925, lng: -0.1985 },
                    zoom: 18,
                });
                setMapLoaded(true);
            }
        };

        if (window.google) {
            loadGoogleMap();
        } else {
            window.initMap = loadGoogleMap;

            if (!document.querySelector('script[src^="https://maps.googleapis.com"]')) {
                console.log("📥 Cargando script de Google Maps...");
                const script = document.createElement("script");
                script.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&callback=initMap`;
                script.async = true;
                script.defer = true;
                document.head.appendChild(script);
            }
        }

        return () => {
            window.initMap = undefined;
        };
    }, []);

    useEffect(() => {
        if (equiposFiltrados.length === 0) {
            setLocalizadoresFiltrados([]); // Opción 1: No mostrar nada si no hay selección
            // Opción 2: Mostrar todos -> setLocalizadoresFiltrados(allLocalizadores);
        } else {
            const filtrados = localizadores.filter(localizador =>
                equiposFiltrados.includes(localizador.id_equipo)
            );
            console.log("AAAAAAAAAAAAAAAAAAA", filtrados);

            setLocalizadoresFiltrados(filtrados);
        }
    }, [equiposFiltrados, localizadores]);

    useEffect(() => {
        if (!mapLoaded) return;

        console.log("🔗 Conectando a WebSocket...");
        const ws = new WebSocket(WEBSOCKET_URL);

        ws.onopen = () => console.log("✅ WebSocket conectado.");
        ws.onmessage = (event) => {
            try {
                const data = JSON.parse(event.data);
                // console.log("📩 Datos recibidos del WebSocket:", data);

                const localizador = localizadoresFiltrados.find(local => local.id == data.idUnico);
                const color = localizador.color ? localizador.color : "#4F0000";
                const newSpeed = Math.round(data.speed * 3.6);

                if (data && data.latitude !== undefined && data.longitude !== undefined) {
                    const newLocation = new window.google.maps.LatLng(data.latitude, data.longitude);

                    if (!polylineRef.current[data.idUnico]) {
                        polylineRef.current[data.idUnico] = new window.google.maps.Polyline({
                            path: [],
                            geodesic: true,
                            strokeColor: '#' + color,
                            strokeOpacity: 1.0,
                            strokeWeight: 15,
                        });
                        polylineRef.current[data.idUnico].setMap(googleMap.current);
                    }

                    const polyline = polylineRef.current[data.idUnico];
                    const updatedCoords = [...polyline.getPath().getArray(), newLocation];
                    polyline.setPath(updatedCoords);

                    if (!markerRef.current[data.idUnico]) {
                        markerRef.current[data.idUnico] = new window.google.maps.Marker({
                            position: newLocation,
                            map: googleMap.current,
                            icon: {
                                path: window.google.maps.SymbolPath.CIRCLE,
                                scale: 8,
                                fillColor: '#' + color,
                                fillOpacity: 1,
                                strokeWeight: 2,
                                strokeColor: "#000000",
                            },
                            label: {
                                text: `${newSpeed} km/h`,
                                color: "black",
                                fontSize: "14px",
                                fontWeight: "bold",
                            }
                        });
                        speedRef.current[data.idUnico] = newSpeed;
                    } else {
                        const currentMarker = markerRef.current[data.idUnico];
                        const prevPosition = currentMarker.getPosition();

                        let step = 0;
                        const steps = 50;
                        const latStep = (newLocation.lat() - prevPosition.lat()) / steps;
                        const lngStep = (newLocation.lng() - prevPosition.lng()) / steps;
                        const speedStep = (newSpeed - speedRef.current[data.idUnico]) / steps;

                        const animateMarker = () => {
                            if (step < steps) {
                                const lat = prevPosition.lat() + latStep * step;
                                const lng = prevPosition.lng() + lngStep * step;
                                currentMarker.setPosition(new window.google.maps.LatLng(lat, lng));
                                speedRef.current[data.idUnico] += speedStep;
                                currentMarker.setLabel({
                                    text: Math.round(speedRef.current[data.idUnico]) + 'km/h Dorsal: ' + localizador.dorsal,
                                    color: "black",
                                    fontSize: "14px",
                                    fontWeight: "bold",
                                    marginTop: '5px',
                                    dorsal: localizador.dorsal,
                                    nombre: localizador.nombre
                                });
                                step++;
                                requestAnimationFrame(animateMarker);
                            }
                        };
                        animateMarker();
                    }
                } else {
                    console.warn("⚠️ Datos inválidos recibidos o estructura incorrecta:", data);
                }
            } catch (error) {
                console.error("❌ Error procesando datos WebSocket:", error);
            }
        };

        ws.onerror = (error) => console.error("❌ Error en WebSocket:", error);
        ws.onclose = () => console.log("🔴 WebSocket desconectado.");

        return () => {
            ws.close();
        };
    }, [mapLoaded, localizadoresFiltrados]);

    return (
        <Grid container xs={12} direction="row" justifyContent="center" alignItems="start" style={{ height: "100vh" }}>
            <Grid container xs={12} sm={10} md={10} lg={3.5} xl={3} direction="row" justifyContent="center" alignItems="center">
                <SelectorLocalizadores equipos={equipos} equiposFiltrados={equiposFiltrados} setEquiposFiltrados={setEquiposFiltrados} markerRef={markerRef} polylineRef={polylineRef}></SelectorLocalizadores>
            </Grid>

            <Grid container xs={11.5} sm={10} md={10} lg={8} xl={8} direction="row" justifyContent="center" alignItems="center">
                <div ref={googleMapRef} className="mapa-participantes" />
            </Grid>
        </Grid>
    );
};

export default MapView;
