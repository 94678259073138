import { Grid, TextField, IconButton, List, ListItem, ListItemText, ListItemSecondaryAction, Button } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import React, { useState } from 'react';

export const SelectorLocalizadores = ({ equipos, setEquiposFiltrados, equiposFiltrados, markerRef, polylineRef }) => {
    const [search, setSearch] = useState("");

    const handleSearchChange = (event) => {
        setSearch(event.target.value);
    };

    const toggleEquipoVisibility = (id, dorsal, index) => {
        if (equiposFiltrados.includes(id)) {
            setEquiposFiltrados(equiposFiltrados.filter(equipoId => equipoId !== id));

            console.log(markerRef.current[20].label.dorsal, dorsal, (index + 20));

            markerRef.current[index + 20].setVisible(false);
            polylineRef.current[index + 20].setVisible(false);

        } else {
            setEquiposFiltrados([...equiposFiltrados, id]);
            if (markerRef.current[index + 20] && markerRef.current[index + 20].getVisible() === false) {
                markerRef.current[index + 20].setVisible(true);
                polylineRef.current[index + 20].setVisible(true);
            }

        }
    };

    const filteredEquipos = equipos.filter(equipo =>
        equipo.nombreEquipo.toLowerCase().includes(search.toLowerCase())
    );

    return (
        <Grid container xs={12} md={12} direction="column" justifyContent="center" alignItems="center" style={{ backgroundColor: '#2E2E2E', padding: '0.5em' }}>



            <Grid
                container
                direction="row"
                justifyContent="start"
                alignItems="start"
                xs={12}
                sx={{ mb: 2 }}
            >
                <Grid item xs={12}>
                    <h2>Equipos</h2>
                </Grid>
                <Grid item xs={12}>
                    <small style={{ color: '#EAC474' }}>Busca tu/s equipos/s favoritos y elige cuáles quieres ver su localización en tiempo real en el mapa.</small>
                </Grid>
            </Grid>

            <TextField
                label="Buscar"
                variant="filled"
                value={search}
                onChange={handleSearchChange}
                fullWidth
                size="small"
                // color='secondary'
                style={{ marginBottom: '0.5em', backgroundColor: 'white', borderRadius: '5px' }}
            />
            <List style={{ width: '100%', backgroundColor: '#404040', borderRadius: '5px', padding: '0.5em' }}>
                {filteredEquipos.map((equipo, index) => (
                    <ListItem key={index} divider>
                        <ListItemText
                            primary={
                                equiposFiltrados.includes(equipo.codigoequipo)
                                    ? <strong>{equipo.dorsal + " - " + equipo.nombreEquipo}</ strong>
                                    : <strong style={{ color: '#b0bec5' }}>{equipo.dorsal + " - " + equipo.nombreEquipo}</ strong>
                            }
                            primaryTypographyProps={{
                                style: {
                                    color: 'white',
                                    fontSize: '1em',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }
                            }}
                        />
                        <ListItemSecondaryAction>
                            <IconButton
                                onClick={() => toggleEquipoVisibility(equipo.codigoequipo, equipo.dorsal, index)}
                                size="small"
                                style={{ color: equiposFiltrados.includes(equipo.codigoequipo) ? "#2196F3" : "#B0BEC5" }}
                            >
                                {equiposFiltrados.includes(equipo.codigoequipo) ? <Visibility style={{ color: '#EAC474' }} /> : <VisibilityOff />}
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                ))}
            </List>

            {/* <Button fullWidth variant="contained" style={{ backgroundColor: '#404040' }} onClick={() => console.log(markerRef.current, polylineRef.current, filteredEquipos)}> MOSTRAR COSAS</Button > */}

        </Grid>
    );
};
