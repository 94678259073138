import { Grid, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react';
import axios from 'axios';


import { useMyContext } from '../MyProvider '; //Variable global del idioma
import { Header } from '../components/Header';
import { HeaderVal } from '../components/HeaderVal';
import { HeaderEn } from '../components/HeaderEn';
import { HeaderGer } from '../components/HeaderGer';
import { SelectorIdioma } from '../components/SelectorIdioma';
import { Streaming } from '../components/Streaming';
import { PiePagina } from '../components/PiePagina';


export const EnDirecto = () => {

    const { miVariableGlobal, setMiVariableGlobal } = useMyContext();
    const [url, setUrl] = useState(null);

    const getUrl = () => {
        axios.get('https://www.transbetxi.com/WS/streaming.php')
            .then(function (response) {
                console.log(response.data.data[0].link);

                setUrl(response.data.data[0].link);
            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(function () {
                // siempre sera executado
            });
    }

    useEffect(() => {
        getUrl();
    }, [])


    return (
        <div className="fade-in" >
            <SelectorIdioma idioma={miVariableGlobal} ></SelectorIdioma>

            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                xs={12}
            >

                {(miVariableGlobal == 1) && (
                    <>
                        <Header></Header>

                        <h2>
                            EN DIRECTO
                        </h2>

                        <strong>Disfruta de la emision en directo del rally Transbetxi.</strong>
                    </>
                )}

                {(miVariableGlobal == 2) && (
                    <>
                        <HeaderVal></HeaderVal>

                        <h2>
                            EN DIRECTE
                        </h2>

                        <strong>Gaudeix de l'emissió en directe del ral·li Transbetxi.</strong>
                    </>
                )}

                {(miVariableGlobal == 3) && (
                    <>
                        <HeaderEn></HeaderEn>

                        <h2>
                            LIVE
                        </h2>

                        <strong>Enjoy the live broadcast of the Transbetxi rally.</strong>
                    </>
                )}

                {(miVariableGlobal == 4) && (
                    <>
                        <HeaderGer></HeaderGer>

                        <h2>
                            LIVE
                        </h2>

                        <strong>Genießen Sie die Live-Übertragung der Transbetxi-Rallye.</strong>
                    </>
                )}

                <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    xs={11.5} sm={11} md={11} lg={11} xl={10}
                    sx={{
                        mt: { xs: 5 },
                        mb: { xs: 5 },
                        // minHeight: { xs: '20vh', sm: '40vh', md: '85vh' },
                        // maxHeight: '50vh' // Ensure the Grid has a defined height
                    }}
                >
                    <Streaming url={url}></Streaming>
                </Grid>
            </Grid>

            <PiePagina></PiePagina>

        </div >

    )
}
