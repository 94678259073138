import React, { useState } from 'react';
import { Document, Text, Page, StyleSheet, Image, View } from '@react-pdf/renderer';
// import logo_inscripcion from '../media/logo_inscirpcion.png';
import logo from '../media/logo.png';

const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        padding: '40',
    },
    imageContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '20',
    },
    image: {
        width: '25%',
    },
    section: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "left",
        marginBottom: '10',
    },
    parrafo: {
        fontSize: '9',
        textAlign: "justify",
        lineHeight: '1.5',
    },
    title: {
        fontSize: '11',
        fontWeight: 'bold',
        marginBottom: '5',
    }

});

export const Pdf = ({ detallesEquipo }) => {
   

    return (
        <Document>
            <Page style={styles.page}>
                <View style={styles.imageContainer}>
                    <Image source={logo} style={styles.image} />
                </View>
                <View style={styles.section}>
                    <Text style={styles.title}>Nº Inscripció: {detallesEquipo.idInscrip}</Text>
                </View>
                <View style={styles.section}>
                    <Text style={styles.title}>Dades del equip:</Text>
                </View>
                <View style={styles.section}>

                    <Text style={styles.parrafo}>
                        Nom del Equip: {detallesEquipo.nombre_equipo}{'\n'}
                        Categoria: {detallesEquipo.tipo_maquina} {'\n'}
                        Telèfon de Urgencia: {detallesEquipo.telefono_urgencia}{'\n'}
                        Telèfon Ubicaciò whattsapp: {detallesEquipo.telefono_ubicacion}{'\n'}
                        Este telèfon pot ser el del pilot o el copilot,
                        és obligatori portar-lo el dia del ral·li.
                    </Text>
                </View>

                <View style={styles.section}>
                    <Text style={styles.title}>Dades del pilot:</Text>
                </View>
                <View style={styles.section}>

                    <Text style={styles.parrafo}>
                        Nom: {detallesEquipo.nombre_piloto}{'\n'}
                        Cognoms: {detallesEquipo.apellidos_piloto}{'\n'}
                        Direcció: {detallesEquipo.direccion_piloto}{'\n'}
                        DNI: {detallesEquipo.dni_piloto}{'\n'}
                        Telèfon: {detallesEquipo.telf_piloto}{'\n'}
                        Correu electrònic: {detallesEquipo.correo_piloto}{'\n'}
                    </Text>
                </View>

                <View style={styles.section}>
                    <Text style={styles.title}>Dades del copilot:</Text>
                </View>
                <View style={styles.section}>
                    <Text style={styles.parrafo}>
                        Nom: {detallesEquipo.nombre_copiloto}{'\n'}
                        Cognoms: {detallesEquipo.apellidos_copiloto}{'\n'}
                        Direcció: {detallesEquipo.direccion_copiloto} {'\n'}
                        DNI: {detallesEquipo.dni_copiloto} {'\n'}
                        Telèfon: {detallesEquipo.telf_copiloto}{'\n'}
                        Correu electrònic: {detallesEquipo.correo_copiloto}{'\n'}
                    </Text>
                </View>

                <View style={styles.section}>
                    <Text style={styles.title}>{'\n'}</Text>
                    <Text style={styles.parrafo}>
                        Pilot i copilot participants declaren conèixer i acceptar el Reglament del XXXIV ral.li TRANSBETXÍ 2025.
                        Pilot i copilot reconeixen que tota responsabilitat civil i penal que hi pogueren incórrer, accidents soferts, desperfectes ocasionats o que ocorregueren per la seua participació i que no estiguen coberts per les pòlisses d’assegurances subscrites per l’entitat organitzadora, queden completament sota el seu càrrec, així doncs tant l’organització com els seus col·laboradors hi queden lliures de tota responsabilitat.
                        Així mateix declaren que el motoret que s’utilitza en la competició compleix tots els requisits que exigeix el reglament, així com el codi de circulació quan recòrrega vies públiques, responsabilitzant-se de qualsevol contrarietat de la legislació aplicable a l’efecte.
                        Mitjançant la signatura del present document, els participants declaren la seua conformitat amb les condicions establertes en la contractació de la pòlissa d’assegurances. Així mateix, afirmen tindre còpia de tota la documentació i impresos necessaris per a una correcta inscripció en la prova, tal i com s’especifica a l’article 8 del reglament de la prova.
                        Els  participants,  en  cas  d’accident,  exclouen  de  tota  responsabilitat  a  l’entitat organitzadora de la prova i hi renuncien a formular cap reclamació.
                    </Text>
                </View>
                <View style={styles.section}>
                    <Text style={styles.title}>Firma Piloto{'                                                             '}</Text>                    <Text style={styles.title}>Firma Copiloto{'\n'}</Text>
                </View>

            </Page>
        </Document>
    );
};
