import axios from 'axios';
import React, { useEffect, useState } from 'react'
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { Tiempos } from '../pages/tiempos/Tiempos';
import { Header } from '../components/Header';
import { HeaderEn } from '../components/HeaderEn';
import { HeaderVal } from '../components/HeaderVal';
import { HeaderGer } from '../components/HeaderGer';
import { PiePagina } from '../components/PiePagina';
import { useMyContext } from '../MyProvider '; //Variable global del idioma
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { SelectorIdioma } from '../components/SelectorIdioma';
import Grid from '@mui/material/Grid';



export const TiemposWp = () => {
	const [loaded, setLoaded] = useState(false);

	const [ediciones, setEdiciones] = useState([]);
	const [edicion, setEdicion] = useState(null);
	const [componentes, setComponentes] = useState([]);
	const [componentesOp, setComponentesOp] = useState([]);
	const [componente, setComponente] = useState(0);
	const [categorias, setCategorias] = useState([]);
	const [categoria, setCategoria] = useState(4);
	const [tramos, setTramos] = useState();
	const [tramosOp, setTramosOp] = useState([]);
	const [tramo, setTramo] = useState();
	const [todosTramos, setTodosTramos] = useState({ codigotramo: 9999995, descripcion: "TODOS LOS TRAMOS", finalizado: null, nombre: "", orden_tramo: 0, resultados: [] })

	const [filtersLoaded, setFiltersLoaded] = useState(false);
	const [tiempos, setTiempos] = useState([]);
	const [general, setGeneral] = useState([]);

	const { miVariableGlobal, setMiVariableGlobal } = useMyContext();

	const firstLoad = () => {
		axios.get('https://www.transbetxi.com/WS/edicion.php')
			.then(function (response) {
				// manejar respuesta exitosa
				let temp = [];
				(response.data).map((edicion, index) => {
					const edicionOption = { label: " " + edicion.nombre + " ", id: edicion.codigoedicion }
					temp.push(edicionOption);
				})
				setEdiciones(temp);
				//seteamos la edicion por la que se ve por defecto el select
				setEdicion(temp[temp.length - 1]);
				setLoaded(true);
			})
			.catch(function (error) {
				// manejar error
				console.log(error);
			})
			.finally(function () {
				// siempre sera executado
			});

		axios.get('https://www.transbetxi.com/WS/tiempos.php')
			.then(function (response) {
				setTiempos(response.data);
			})
			.catch(function (error) {
				// manejar error
				console.log(error);
			})
			.finally(function () {
				// siempre sera executado
			});


		// axios.get('http://192.168.78.17/WS/general.php')
		// 	.then(function (response) {
		// 		console.log("general", response.data);
		// 	})
		// 	.catch(function (error) {
		// 		// manejar error
		// 		console.log(error);
		// 	})
		// 	.finally(function () {
		// 		// siempre sera executado
		// 	});
	}

	useEffect(() => {
		firstLoad();
	}, [])

	useEffect(() => {
		if (edicion !== null) {
			axios.get('https://www.transbetxi.com/WS/categorias.php')
				.then(function (response) {
					// manejar respuesta exitosa
					setCategorias(response.data);
				})
				.catch(function (error) {
					// manejar error
					console.log(error);
				})
				.finally(function () {
					// siempre sera executado
				});

			axios.get('https://www.transbetxi.com/WS/tramos.php')
				.then(function (response) {
					setTramos(response.data);
					formatearListaTramos(response.data);
					setFiltersLoaded(true);
				})
				.catch(function (error) {
					// manejar error
					console.log(error);
				})
				.finally(function () {
					// siempre sera executado
				});
		}
		setTramo(todosTramos);
	}, [edicion])


	const formatearListaTramos = (data) => {

		let temp = data.find((dat) => dat.edicion === edicion.id)
		temp = (temp.resultados)
		temp.map((equipo, index) => {
			let nombreTramo = decodeHtml(equipo.descripcion);
			temp[index].descripcion = nombreTramo;
		});
		setTramosOp(temp);
		// console.log(temp.resultados)
	}

	const decodeHtml = (htmlText) => {
		const htmlFragment = htmlText;
		const parser = new DOMParser();
		const doc = parser.parseFromString(htmlFragment, 'text/html');
		let textoUTF8 = doc.body.textContent;
		textoUTF8 = textoUTF8.toUpperCase();
		return (textoUTF8);
	}

	const handleChange = (event) => {
		switch (event.target.name) {
			case "categoria": setCategoria(event.target.value);
				break;
			case "edicion": setEdicion(event.target.value);
				break;
			case "tramo": setTramo(event.target.value);
				break;
		}
	};
	const theme = createTheme({
		palette: {
			primary: {
				light: '#757ce800',
				main: '#3f50b500',
				dark: '#00288400',
				contrastText: '#fffFFF00',
			},
			secondary: {
				light: '#ff796100',
				main: '#f4433600',
				dark: '#ba000d00',
				contrastText: '#0000000',
			},
		},
	});



	return (
		<div>
			<div>
				<SelectorIdioma idioma={miVariableGlobal} ></SelectorIdioma>

				{(miVariableGlobal == 1) && (
					<Header></Header>
				)}

				{(miVariableGlobal == 2) && (
					<HeaderVal></HeaderVal>
				)}

				{(miVariableGlobal == 3) && (
					<HeaderEn></HeaderEn>
				)}

				{(miVariableGlobal == 4) && (
					<HeaderGer></HeaderGer>
				)}
				{/* <Grid container xs={12} md={12} direction="column" justifyContent="center" alignItems="center" style={{ backgroundColor: '#2E2E2E', paddingBottom: '1em' }}>
					<Grid container direction="column" xs={10} md={6} style={{ alignItems: 'center', justifyContent: 'center', textAlign: 'center', marginTop: '1em' }}>
						<strong>El contenido se publicara proximamente.</strong>
					</Grid>
				</Grid> */}
			</div>
			{(loaded === false) && (
				<div className='flex-container spinner'>
					<div className='loading-spinner'>
					</div>
				</div>
			)}
			{(loaded === true) && (
				<Grid
					container
					direction="column"
					justifyContent="center"
					alignItems="center"
					xs={12}
				>
					<Grid
						container
						direction="column"
						justifyContent="center"
						alignItems="center"
						xs={11} sm={10} md={8} lg={6} xl={4}
						gap={1}
					>
						<Select
							size='small'
							className='select'
							id="demo-simple-select"
							value={edicion}
							label="Age"
							name="edicion"
							onChange={handleChange}
							style={{ minWidth: '100%' }}
						>
							{ediciones.map((edicionElement, index) => (
								<MenuItem key={index} value={edicionElement}>{edicionElement.label}</MenuItem>
							))}
						</Select>

						{(categoria > 0) && (
							<Select
								size='small'
								className='select'
								id="demo-simple-select"
								value={categoria}
								label="Age"
								name="categoria"
								onChange={handleChange}
								style={{ minWidth: '100%' }}
							>
								{categorias.map((categoriaOption, index) => (
									<MenuItem key={index} value={categoriaOption.codigocategoria}>{categoriaOption.nombre}</MenuItem>
								))}
							</Select>
						)}
						{(tramosOp.length > 0) && (

							<Select
								size='small'
								className='select'
								id="demo-simple-select"
								value={tramo ? tramo : todosTramos}
								label="Age"
								name="tramo"
								onChange={handleChange}
								style={{ minWidth: '100%' }}
							>
								<MenuItem key={0} value={todosTramos}>{todosTramos.descripcion}</MenuItem>
								{tramosOp.map((categoriaOption, index) => (
									<MenuItem key={index} value={categoriaOption}>{categoriaOption.nombre + " - " + categoriaOption.descripcion}</MenuItem>
								))}
							</Select>
						)}
					</Grid>

					{(filtersLoaded === true && tiempos.length > 0) && (


						<Grid
							container
							direction="row"
							justifyContent="center"
							alignItems="center"
							xs={12}
						>
							<Grid
								container
								direction="column"
								justifyContent="center"
								alignItems="center"
								xs={11} sm={10} md={8} lg={6} xl={4}
							>
								<Tiempos tiempos={tiempos} edicion={edicion.id} tramo={tramo} categoria={categoria} />

							</Grid>
						</Grid>


					)}
					<PiePagina></PiePagina>


				</Grid>
			)}
		</div>
	)
}
