import React, { useState } from 'react'
import { HeaderGer } from '../../components/HeaderGer';
import { PiePagina } from '../../components/PiePagina';
import Grid from '@mui/material/Grid';
import inscripcion from '../../media/icons/inscribirse.png';
import { FormularioInscripciones } from './FormularioInscripciones';

export const InscripcionesGer = () => {
    const [mostrarForm, setMostrarForm] = useState(false);

    return (
        <div className="fade-in">
            <HeaderGer></HeaderGer>
            <Grid container xs={12} md={12} direction="column" justifyContent="flex-start" alignItems="center" style={{ backgroundColor: '#2E2E2E', minHeight: '70vh' }}>
                <Grid container xs={12} md={12} direction="row" justifyContent="center" alignItems="center">
                    <h1>ANMELDUNGEN</h1>
                </Grid>

                <Grid Container xs={10} md={6} Direction="column" justifyContent="flex-start" alignItems="flex-start" style={{ textAlign: 'left', color: '#EAC474' }} >


                    <p>Das korrekte Registrierungsverfahren besteht aus den folgenden Teilen und muss in derselben Reihenfolge befolgt werden:</p>
                    <ol>
                        <li>Registrierung durch den Piloten, den Co-Piloten und das Maschinenteam auf dem Formular, das auf der Website www.transbetxi.com ausgefüllt werden muss.</li>
                        <li>Bei Ihrer Registrierung erscheint die Schaltfläche „Bestätigen und herunterladen“. Ein Dokument wird heruntergeladen und Ihnen per E-Mail zugesandt. Bitte bringen Sie es am Tag der Zahlung unterschrieben mit.</li>
                        <p>Es muss am Tag der Zahlung der Registrierungszahlung ordnungsgemäß ausgefüllt und unterzeichnet werden.</p>
                        <strong>PILOT:</strong>
                        <ol>
                            <li>DRUCKEN SIE EINE KOPIE DER AUF DER WEBSITE VORGENOMMENEN REGISTRIERUNG.</li>
                            <li>1 FOTOKOPIE UND ORIGINAL DER FEDERATIVEN LIZENZ (Vorder- und Rückseite).</li>
                            <li>1 FOTOKOPIE UND ORIGINAL DES NATIONALEN PERSONALDOKUMENTS (Vorder- und Rückseite).</li>
                            <li>1 Fotokopie und Original des Führerscheins: B1 (Vorder- und Rückseite).</li>
                        </ol>
                        <strong>KOPILOT:</strong>
                        <ol>
                            <li>DRUCKEN SIE EINE KOPIE DER AUF DER WEBSITE VORGENOMMENEN REGISTRIERUNG.</li>
                            <li>1 FOTOKOPIE UND ORIGINAL DER FEDERATIVEN LIZENZ (Vorder- und Rückseite).</li>
                            <li>1 FOTOKOPIE UND ORIGINAL DES NATIONALEN PERSONALDOKUMENTS (Vorder- und Rückseite).</li>
                            <li>1 Fotokopie und Original des Führerscheins: B1 (Vorder- und Rückseite) oder eine für das aktuelle Jahr gültige medizinische Untersuchung.</li>
                        </ol>
                    </ol>
                </Grid>

                <Grid container xs={10} md={10} direction="column" justifyContent="center" alignItems="center">
                    <h2>Föderationsformular</h2>
                    <img src={inscripcion} alt="Icon" />
                    <a href="https://fmcv.es/wp-content/uploads/2023/10/seguro-1dia.pdf" target="_blank" style={{ color: '#EAC474' }}> Hier herunterladen </a>
                </Grid>
                <Grid container xs={7} md={3} direction="column" justifyContent="center" alignItems="center" style={{ textAlign: 'left', color: '#EAC474' }}>
                    {mostrarForm === true && (
                        <FormularioInscripciones setMostrarForm={setMostrarForm} />
                    )}
                    <div className="button-adm" style={{ color: 'black' }} onClick={() => setMostrarForm(true)}>
                        Registrieren
                    </div>
                </Grid>
            </Grid>
            <PiePagina></PiePagina>

        </div>
    )
}
