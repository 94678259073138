import React from 'react';
import { HeaderGer } from '../../components/HeaderGer';
import { PiePagina } from '../../components/PiePagina';
import Grid from '@mui/material/Grid';
import { TablaActividadesGer } from '../tablaActividades/TablaActividadesGer';


export const ActividadesGer = () => {
  return (
    <div className="fade-in">
      <HeaderGer></HeaderGer>

      <Grid container xs={12} md={12} direction="row" justifyContent="center" alignItems="center" style={{ backgroundColor: '#2E2E2E', paddingBottom: '1em' }}>
        <h1>AKTIVITÄTEN</h1>
      </Grid>

      <Grid container xs={12} md={12} direction="row" justifyContent="center" alignItems="center" style={{ backgroundColor: '#2E2E2E', paddingBottom: '1em' }}>
        <Grid container xs={11.5} md={11} direction="row" justifyContent="center" alignItems="center" style={{ backgroundColor: '#2E2E2E' }}>
          <TablaActividadesGer></TablaActividadesGer>
        </Grid>
      </Grid>



      <PiePagina></PiePagina>
    </div>
  )
}
