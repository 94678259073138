import React from 'react'
import { useNavigate } from 'react-router-dom';
import { HeaderHomeEs } from '../../components/HeaderHomeEs'
import { PiePagina } from '../../components/PiePagina';
import Grid from '@mui/material/Grid';
import portapapeles from '../../media/icons/portapapeles1.png';
import mapa from '../../media/icons/mapa1.png';
import casco from '../../media/icons/casco.png';
import crono from '../../media/icons/cronografo-1.png';
import libro from '../../media/icons/libro-de-normas.png';
import inscripcion from '../../media/icons/inscribirse.png';
import bar from '../../media/icons/tenedor.png';
import historia from '../../media/icons/libro.png';
import { Link } from '@mui/material';
import InstagramIcon from '@mui/icons-material/Instagram';


export const HomeEs = () => {

    const Navigate = useNavigate();


    return (
        <Grid className="fade-in">
            <HeaderHomeEs></HeaderHomeEs>
            <Grid container
                spacing={{ xs: 2, md: 3 }}
                columns={{ xs: 4, sm: 8, md: 12 }}
                direction="column"
                justifyContent="center"
                alignItems="center">

                <Grid container
                    direction="column"
                    // spacing={{ xs: 2, md: 3 }} 
                    // columns={{ xs: 4, sm: 8, md: 12 }}
                    style={{ alignItems: 'center', justifyContent: 'center', backgroundColor: '#2E2E2E', paddingTop: '5em' }}>
                    <Grid container
                        xs={10} md={8}
                        style={{ alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
                        <h1>¡BIENVENIDOS AL RALLY DE BETXÍ!</h1>
                        <h1>COMPETICIÓN, DIVERSIÓN, MOTOR, Y SOBRETODO, AMISTAD Y COMPAÑERISMO</h1>
                    </Grid>
                    {/* <Grid container xs={10} md={4} direction="column" justifyContent="center" alignItems="center" style={{ marginTop: '2em', marginBottom: '1em', marginLeft: '1em', textAlign: 'center' }}>
                        <h2>GRÁCIAS A TODOS LOS PATROCINADORES Y COLABORADORES POR EL APOYO Y TRABAJO ESTOS DÍAS</h2>
                    </Grid>
                    <Grid container xs={10} md={4} direction="column" justifyContent="center" alignItems="center" style={{ marginTop: '2em', marginBottom: '1em', marginLeft: '1em' }}>
                        <img src="https://www.transbetxi.com/img/patros2024.png" alt="" />
                    </Grid>
                    <Grid container
                        xs={10} md={8}
                        style={{ alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
                        <h2>ORGANIZADO POR </h2>
                    </Grid>
                    <Grid container xs={10} md={4} direction="column" justifyContent="center" alignItems="center" style={{ marginTop: '2em', marginBottom: '1em', marginLeft: '1em' }}>
                        <img src="https://www.transbetxi.com/img/betxi-logo.png" alt="" />
                    </Grid>*/}
                    <Grid container xs={11} md={4} gap={1} direction="column" justifyContent="center" alignItems="center" style={{ marginTop: '2em', marginBottom: '1em', marginLeft: '1em' }}>
                        <img src="https://www.transbetxi.com/img/CARTEL_OFICIAL_2025_WEB.jpg" alt="" style={{ minWidth: '100%', maxWidth: '100%' }} />
                        <small style={{ color: '#EAC474', textAlign: 'center', fontFamily: 'Roboto' }}>
                            Ilustración Sandra Sánchez Merino
                            <Link href="https://www.instagram.com/samdra.sm" underline='none' target="_blank" style={{ color: '#EAC474', fontWeight: 800 }}><InstagramIcon sx={{fontSize: 20, mb: -0.7, ml: 0.5}}/>samdra.sm</Link>
                        </small>
                    </Grid>

                </Grid>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    // spacing={{ xs: 2, sm: 2, md: 3, ln: 4 }}
                    // columns={{ xs: 2, sm: 2, md: 1, ln: 4 }}
                    style={{ backgroundColor: '#2E2E2E', paddingTop: '5em', paddingBottom: '1em', margin: '-1px' }}
                >
                    <Grid container direction="column" xs={8} md={4} style={{ alignItems: 'center', justifyContent: 'center', textAlign: 'center', marginTop: '1em' }}>
                        <img src={mapa} alt="Icon" style={{ minWidth: '1em', maxWidth: '5em' }} />
                        <h1>MAPA DE LOS TRAMOS</h1>
                        <strong>Consulta la ubicacion y recorrido de los tramos ,ademas de poder ver en tiempo real a tu equipo favorito.</strong>
                        <div class="sirk2" onClick={() => Navigate('/mapas')}>
                            <a class="element-custom">Mapas</a>
                        </div>
                    </Grid>
                    <Grid container direction="column" xs={8} md={4} style={{ alignItems: 'center', justifyContent: 'center', textAlign: 'center', marginTop: '1em' }}>
                        <img src={portapapeles} alt="Icon" style={{ minWidth: '1em', maxWidth: '5em' }} />
                        <h1>PROGRAMA ACTIVIDADES</h1>
                        <strong>Proximamente colgaremos el Programa de actividades del transbetxi 2024.</strong>
                        <div class="sirk2" onClick={() => Navigate('/actividades')}>
                            <a class="element-custom">Actividades</a>
                        </div>
                    </Grid>
                </Grid>

                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    // spacing={{ xs: 2, sm: 2, md: 3, ln: 4 }}
                    // columns={{ xs: 2, sm: 2, md: 1, ln: 4 }}
                    style={{ backgroundColor: '#2E2E2E', paddingTop: '1em', paddingBottom: '1em', margin: '-1px' }}
                >
                    <Grid container direction="column" xs={8} md={4} style={{ alignItems: 'center', justifyContent: 'center', textAlign: 'center', marginTop: '1em' }}>
                        <img src={bar} alt="Icon" style={{ minWidth: '1em', maxWidth: '5em' }} />
                        <h1>DÓNDE COMER</h1>
                        <strong>Bares y restaurantes para comer durante los días del rally.</strong>
                        <div class="sirk2" onClick={() => Navigate('/donde-comer')}>
                            <a class="element-custom">Dónde comer</a>
                        </div>
                    </Grid>
                    <Grid container direction="column" xs={8} md={4} style={{ alignItems: 'center', justifyContent: 'center', textAlign: 'center', marginTop: '1em' }}>
                        <img src={historia} alt="Icon" style={{ minWidth: '1em', maxWidth: '5em' }} />
                        <h1>HISTORIA</h1>
                        <strong>Conoce de donde viene el rally Transbetxi y sus raíces.</strong>
                        <div class="sirk2" onClick={() => Navigate('/historia')}>
                            <a class="element-custom">Historia</a>
                        </div>
                    </Grid>
                </Grid>

                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    // spacing={{ xs: 2, sm: 2, md: 3, ln: 4 }}
                    columns={{ xs: 2, sm: 2, md: 1, ln: 4 }}
                    style={{ backgroundColor: '#2E2E2E', marginTop: '0em', paddingTop: '1em', paddingBottom: '1em', margin: '-1px' }}
                >
                    <Grid container direction="column" xs={8} md={4} style={{ alignItems: 'center', justifyContent: 'center', textAlign: 'center', marginTop: '1em' }}>
                        <img src={casco} alt="Icon" style={{ minWidth: '1em', maxWidth: '5em' }} />
                        <h1>EQUIPOS</h1>
                        <strong>Consulta toda la información de tu equipo favorito.</strong>
                        <div class="sirk2" onClick={() => Navigate('/equipos')}>
                            <a class="element-custom">Equipos</a>
                        </div>
                    </Grid>
                    <Grid container direction="column" xs={8} md={4} style={{ alignItems: 'center', justifyContent: 'center', textAlign: 'center', marginTop: '1em' }}>
                        <img src={crono} alt="Icon" style={{ minWidth: '1em', maxWidth: '5em' }} />
                        <h1>CLASIFICACIÓN</h1>
                        <strong>Aqui podrás consultar tanto los tiempos de este rally como de los anteriores.</strong>
                        <br />
                        <strong>No olvides que algunos tiempos de este rally son provisionales y se suben de forma orientativa hasta que se confirmen.</strong>
                        <div class="sirk2" onClick={() => Navigate('/tiempos')}>
                            <a class="element-custom">Clasificación</a>
                        </div>
                    </Grid>
                </Grid>

                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    // spacing={{ xs: 2, sm: 2, md: 3, ln: 4 }}
                    columns={{ xs: 2, sm: 2, md: 1, ln: 4 }}
                    style={{ backgroundColor: '#2E2E2E', marginTop: '0em', paddingTop: '1em', paddingBottom: '1em', margin: '-1px' }}
                >
                    <Grid container direction="column" xs={8} md={4} style={{ alignItems: 'center', justifyContent: 'center', textAlign: 'center', marginTop: '1em' }}>
                        <img src={inscripcion} alt="Icon" style={{ minWidth: '1em', maxWidth: '5em' }} />
                        <h1>INSCRIPCIONES</h1>
                        <strong>Pronto se indicarán los pasos necesarios para inscribirse en el rally.</strong>
                        <div class="sirk2" onClick={() => Navigate('/inscripciones')}>
                            <a class="element-custom" >Inscripciones</a>
                        </div>
                    </Grid>
                    <Grid container direction="column" xs={8} md={4} style={{ alignItems: 'center', justifyContent: 'center', textAlign: 'center', marginTop: '1em' }}>
                        <img src={libro} alt="Icon" style={{ minWidth: '1em', maxWidth: '5em' }} />
                        <h1>REGLAMENTOS</h1>
                        <strong>En este apartado podrás encontrar tanto el reglamento técnico como el reglamento de la prueba.</strong>
                        <div class="sirk2" onClick={() => Navigate('/reglamentos')}>
                            <a class="element-custom">Reglamentos</a>
                        </div>
                    </Grid>
                </Grid>
            </Grid>
            <PiePagina></PiePagina>
        </Grid >

    )
}
