import { Grid, Typography } from '@mui/material'
import React from 'react'

export const Streaming = ({ url }) => {
    return (
        <>
            {url === null || url === '' || url === ' '
                ? <h2>No hay ninguna emision en directo.</h2>
                : <iframe
                    src={url}
                    allow="autoplay"
                    title="Transbetxi en directo"
                    style={{
                        width: '100%',
                        minHeight: '85vh',
                        border: 'none',
                        // overflow: 'hidden'
                    }}
                ></iframe>
            }

        </>
    )
}
