import { Grid, Typography } from '@mui/material'
import axios from 'axios';
import React, { useEffect } from 'react'
import { useState } from 'react';

export const LeyendaPenalizaciones = () => {

    const baseUrl = 'https://www.transbetxi.com/WS/'

    const [penalizaciones, setPenalizaciones] = useState([]);

    const getPen = async () => {
        try {
            const response = await axios.get(baseUrl + 'penalizaciones.php?');

            setPenalizaciones(response.data)
            // Procesar la respuesta y actualizar las opciones si es necesario
            return response.data;

        } catch (error) {
            console.error('Error al verificar el token:', error);
            return null;
        }
    }

    useEffect(() => {
        getPen();
    }, [])



    return (
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            xs={12}
            sx={{ color: '#eac474' }}
            padding={1.5}
        >
            {penalizaciones.map((info, index) => (
                <Typography variant='body1' style={{ textAlign: 'center' }}>· {info.codigo} - {info.nombre}</Typography>
            ))}

        </Grid>

    )
}
