import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { getCookie } from "../../../utils/cookies";



import { Button, Grid, TextField, Typography } from '@mui/material';
import { Streaming } from '../../Streaming';

export const EditarStreaming = ({ setMostrarStreaming }) => {

    const [url, setUrl] = useState(null);

    const handledOnChange = (event) => {
        setUrl(event.target.value);
    }

    const getUrl = () => {
        axios.get('https://www.transbetxi.com/WS/streaming.php', {
            params: {
                token: getCookie('PHPSESSID'),
            },
        })
            .then(function (response) {
                // console.log(response.data.data[0].link);

                setUrl(response.data.data[0].link);
            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(function () {
                // siempre sera executado
            });
    }

    const updateUrl = async (url) => {
        try {

            const response = await axios.put('https://www.transbetxi.com/WS/updateStreaming.php?', {
                token: getCookie('PHPSESSID'),
                link: url
            });

            // console.log(response.data.data[0].link);
            setUrl(url);
        } catch (error) {
            console.error('Error al actualizar la URL:', error);
        }
    };


    useEffect(() => {
        getUrl();
    }, [])

    return (
        <div className='fade-in'>

            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                xs={12}
                sx={{ mb: 3, mt: 2 }}
            >
                <Button
                    fullWidth
                    variant='contained'
                    color='inherit'
                    size='large'
                    sx={{ pb: 1.75, pt: 1.75, backgroundColor: '#EAC474' }}
                    onClick={() => setMostrarStreaming(false)}
                >
                    Volver
                </Button>
            </Grid>
            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                xs={12}
                gap={4}
            >

                <Grid container
                    direction="column"
                    justifyContent="center"
                    alignItems="start"
                    xs={12}
                >
                    <Typography variant='h3'>URL del streaming</Typography>
                    <strong style={{ fontWeight: 400 }}>Escribe aqui la URL de la emision en directo</strong>
                    <br />
                    <strong>IMPORTANTE: Al finalizar el evento se debe borrar la URL y dejarla en blanco</strong>
                </Grid>

                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    xs={12}
                    gap={2}
                >
                    <Grid item xs={12}>
                        <TextField fullWidth value={url} label='URL Streaming' variant='filled' color='primary' sx={{ backgroundColor: 'white', borderRadius: '5px' }} onChange={handledOnChange}></TextField>
                    </Grid>

                    <Grid item xs={5}>
                        <Button fullWidth variant='outlined' color='error' size='large' sx={{ pb: 1.75, pt: 1.75 }} onClick={() => { updateUrl(null); setUrl(''); }}>Quitar streaming</Button>
                    </Grid>

                    <Grid item xs={5}>
                        <Button fullWidth variant='contained' color='inherit' size='large' sx={{ pb: 1.75, pt: 1.75, backgroundColor: '#EAC474' }} onClick={() => updateUrl(url)}>Actualizar</Button>
                    </Grid>

                </Grid>
            </Grid>

            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="start"
                xs={12}
                gap={2}
                sx={{
                    mt: { xs: 5 },
                    mb: { xs: 5 },
                    // minHeight: { xs: '20vh', sm: '40vh', md: '85vh' },
                    // maxHeight: '50vh' // Ensure the Grid has a defined height
                }}
            >
                <Grid item xs={12}>
                    <Typography variant='h3'>Emitiendo ahora mismo en la web:</Typography>
                </Grid>

                <Streaming url={url}></Streaming>

            </Grid>
        </div>
    )
}
