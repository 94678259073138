import React, { useEffect } from 'react';
import { Header } from '../../components/Header';
import { PiePagina } from '../../components/PiePagina';
import Grid from '@mui/material/Grid';
import { TablaActividadesEs } from '../tablaActividades/TablaActividadesEs';

export const ReglamentoPruebaEs = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])


    return (
        <div className="fade-in" >
            <Header></Header>
            <Grid container xs={12} md={12} direction="row" justifyContent="center" alignItems="center" style={{ backgroundColor: '#2E2E2E', textAlign: 'center' }}>
                <h1>REGLAMENTO DE LA PRUEBA</h1>
            </Grid>

            <Grid container xs={12} md={12} direction="column" justifyContent="center" alignItems="center" style={{ backgroundColor: '#2E2E2E', overflow: 'auto' }}>
                <Grid container xs={10} md={8} direction="column" justifyContent="center" alignItems="center">

                    <iframe src="https://drive.google.com/viewerng/viewer?embedded=true&url=https://transbetxi.com/REGLAMEN_TRANSBETXI_2025_ES.pdf" style={{ minHeight: '100vh', minWidth: '100%' }}></iframe>

                    {/* <div style={{ maxWidth: '100%', color: '#EAC474', textAlign: 'left' }}>
                        <h3>PRÓLOGO - DEFINICIÓN DEL CONCEPTO DE ACTITUD ANTIDEPORTIVA.</h3>
                        <p>Queridos participantes, con esta introducción, queremos haceros conscientes de que, además de tener unos derechos y servicios que le asisten, al participar en el rally TRANSBETXÍ, tiene también una serie de normas de obligado cumplimiento. </p>
                        <p>Estas normas constituyen un garante para el correcto devenir de la carrera y le ofrecen la asistencia y el mismo tratamiento a todos los equipos que toma parte.</p>
                        <p>La organización, mediante las facultades que le atribuyen los contenidos de los artículos 11, 22 y 23 del presente reglamento, en contra de cualquier hecho o actitud que atente contra la seguridad, integridad y asistencia a los demás participantes, organizadores y público en general, por parte de un equipo o de alguna persona relacionada con él, y por este "principio de actitud antideportiva", queremos hacer pública la reserva del derecho a tomar todas las medidas que crea convenientes, para corregir o eliminar dichos actos y comportamientos.</p>
                        <p>En beneficio de todos y agradeciendo su colaboración, reciba nuestro saludo.</p>

                        <h3>01.- PROGRAMA Y HORARIO OFICIAL DE ACTIVIDADES</h3>

                        <Grid container xs={12} md={12} direction="row" justifyContent="center" alignItems="center" style={{ backgroundColor: '#2E2E2E', paddingBottom: '1em' }}>
                            <Grid container xs={12} md={12} direction="row" justifyContent="center" alignItems="center" style={{ backgroundColor: '#2E2E2E' }}>
                                <TablaActividadesEs></TablaActividadesEs>
                            </Grid>
                        </Grid>

                        <h3>02.- ORGANIZACIÓN, DISPOSICIONES GENERALES</h3>

                        <h4>Art.02-01 – GENERALIDADES</h4>
                        <p>La entidad organizadora prepara una prueba deportiva de motocultores que se celebrará los días 5,6 y 7 de abril de 2024, llamada XXXIV RALLY TRANSBETXÍ 2024</p>

                        <h4>Art.02-02 - GENERALIDADES NORMATIVA DE APLICACIÓN</h4>
                        <p>Esta prueba se organiza de acuerdo con los segundos reglamentos</p>
                        <ul>
                            <li>Reglamento Deportivo de la FMCV</li>
                            <li>Reglamento General de Enduro FMCV o RALLI TT</li>
                            <li>El presente Reglamento Particular.</li>
                        </ul>

                        <h4>Art.02- 03.- SEDE DEL COMITÉ DE ORGANIZACIÓN</h4>
                        <p>Entidad organizadora:</p>
                        <ul>
                            <li>El ilustrísimo AYUNTAMIENTO DE BETXÍ.</li>
                            <li>La secretaría del comité organizador estará ubicada en el Ayuntamiento de Betxí. Tel. 964 620 002.</li>
                        </ul>

                        <h4>Art. 02-04.- CARGOS OFICIALES</h4>
                        <p>Juez / árbitro: Fco. Javier Ferrandis Bordería / Lic. 500547-RF</p>
                        <p>Comisario Técnico: Josep Manel Adsuara Vicent / Lic. En trámite</p>
                        <p>Director de Carrera: David Brisach Miravet / Lic. En trámite</p>
                        <p>Cronometrador: Agustín Ferreres Beltrán / Lic. 622497-CS</p>

                        <h4>Art. 02-05.- OFICIALES</h4>
                        <p>El organizador dispone de dos tipos de oficiales en la prueba.</p>

                        <h4>Art. 02-05-01 OFICIALES DEPORTIVOS</h4>
                        <p>Son aquellas personas que tienen potestad de tomar decisiones sobre el funcionamiento de la carrera.
                            Estos oficiales estarán distinguidos del resto de alguna forma para facilitar a los participantes la resolución de cualquier problema o duda.
                        </p>

                        <h4>Art. 02-05-02 OFICIALES DE ORGANIZACIÓN</h4>
                        <p>Son aquellas personas responsables de todas las tareas extradeportivas y necesarias para poder realizar la prueba. (Banderas, controles de paso, personal de ayuda, etc...)
                            Ningún oficial de organización tiene potestad para decidir sobre ningún tema deportivo.
                            Si que se pueden aprovechar sus medios de comunicación interna para que algún Oficial Deportivo pueda informar a algún participante sobre alguna cuestión o duda de que sea necesario para la prueba.
                        </p>

                        <h4>Art. 02-06.- TABLERO DE ANUNCIOS OFICIALES – MODIFICACIONES</h4>
                        <p>Todas las modificaciones o disposiciones complementarias se pondrán en conocimiento de los participantes lo antes posible, mediante complementos fechados y numerados que formarán parte de este Reglamento Particular.
                            Estos complementos, así como otras resoluciones, citaciones, penalizaciones, y otros documentos, serán expuestos en el Tablón Oficial de Avisos en sala de prensa habilitada para la prueba.
                        </p>
                        <p>En todas las modificaciones figurará la hora de publicación. </p>

                        <h3>03.- RECORRIDO</h3>
                        <h4>Art. 03-01.- RECORRIDO</h4>
                        <p>El XXXIV Rally TRANSBETXÍ 2024, consta de un recorrido común para todos los participantes, con ocho (6) tramos cronometrados, distribuidos de la siguiente manera:</p>
                        <table>
                            <thead>
                                <tr>
                                    <th>SÁBADO 15 DE ABRIL</th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>09:30 H.</td>
                                    <td>TC1</td>
                                    <td>TRAMO CRONOMETRADO</td>
                                </tr>
                                <tr>
                                    <td>10:30 H.</td>
                                    <td>TC2</td>
                                    <td>TRAMO CRONOMETRADO</td>
                                </tr>
                                <tr>
                                    <td>15:30 H.</td>
                                    <td>TC3</td>
                                    <td>TRAMO CRONOMETRADO</td>
                                </tr>
                                <tr>
                                    <td>16:30 H.</td>
                                    <td>TC4</td>
                                    <td>TRAMO CRONOMETRADO</td>
                                </tr>
                            </tbody>
                        </table>
                        <br />
                        <table>
                            <thead>
                                <tr>
                                    <th>DOMINGO 16 DE ABRIL</th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>09:30 H.</td>
                                    <td>TC5</td>
                                    <td>TRAMO CRONOMETRADO</td>
                                </tr>
                                <tr>
                                    <td>10:30 H.</td>
                                    <td>TC6</td>
                                    <td>TRAMO CRONOMETRADO</td>
                                </tr>
                            </tbody>
                        </table>

                        <p>La clasificación de la edición de 2023 por categorías servirá para establecer el orden de salida a la etapa del sábado día 6 de abril de 2024.
                            Queda totalmente prohibido realizar marcas sobre señalizaciones de tráfico, hits, pavimentaciones, edificaciones o márgenes de la carretera y caminos por parte de los concursantes u otras personas relacionadas con ellos.
                        </p>
                        <p>Por otra parte, también los entrenamientos del recorrido efectuados al margen de la prueba por los equipos a partir del domingo 31 Marzo de 2024, están PROHIBIDOS. Se establece por este supuesto el mismo procedimiento de reclamación y actuaciones de oficio establecidos en el artículo 7 de este reglamento, que, además de toda infracción denunciada por la Policía Local o cualquier otro organismo gubernativo, implica el incumplimiento de este artículo, considerado como FALTA MUY GRAVE, contemplada en el régimen disciplinario de este reglamento.</p>

                        <h3>04.- INSCRIPCIÓN DE LOS PARTICIPANTES</h3>
                        <h4>Art. 04-01.- VEHÍCULOS ADMITIDOS</h4>
                        <p>Son admitidos para participar en esta prueba los motocultores sin excepción, pero debe tenerse en cuenta que deben estar comprendidos dentro de las siguientes limitaciones y/o características técnicas</p>
                        <ul>
                            <li>Ser motocultores de serie o tradicionales, evolucionados o no, que conservan motorización (sin cambios externos ni refrigeración líquida), embrague y caja de cambios originales de sus marcas.</li>
                            <li>No son admitidas otras fórmulas de tracción (excepto invitación de la organización) y, con carácter general, es obligado que todos los vehículos participantes conserven el resto de las características del vehículo tradicional, como pueden ser la dirección, el sistema de unión motriz-carro, etc.</li>
                        </ul>

                        <h4>Art. 04-02.- MEDIDAS DE SEGURIDAD</h4>
                        <p>Cada equipo participante, para estar localizable, recibir notificaciones, además de poder comunicar accidentes y otras incidencias, deberá llevar un teléfono móvil con WhatsApp.
                            Todos los participantes deben equiparse obligatoriamente con los siguientes elementos de protección:
                        </p>
                        <ol>
                            <li>Casco homologado de enduro, cros o integral (son aquellos que llevan una numeración específica, (que comienza por 05, P ó J) el casco correctamente colocado y ajustado con la correa bien pasada). La numeración (etiquetado del casco) debe estar claramente visible.
                                Se enviará un documento con la normativa a todos los socios para comprobar si están homologados.
                                <ul>a. Se revisarán todos y no se aceptará ninguna que esté en mal estado, no cumpla la normativa o tenga el etiquetado en mal estado o ilegible Collarín de seguridad, es obligatorio collarín rígidos u ortopédico de motocross.</ul>
                            </li>
                            <li>Protector de pecho, hombro y hombros con coderas.</li>
                            <li>Será obligatorio para el peto integral o coraza, con coderas y mejilloneras integradas. </li>
                            <li>Se recomienda tener al contar las características que deben cumplir y mirar si son compatibles con los collarines rígidos, antes de comprar o actualizar sus protecciones.</li>
                            <li>Guantes de protección (mínimo exigible: EPI laboral por riesgos mecánicos NP3). </li>
                            <li>Rodilleras</li>
                            <li>Botas de enduro y cross (mínimo exigible: Media caña).</li>
                            <li>Será obligatorio un extintor de 1kg de polvo químico ABC correctamente fijado al vehículo.</li>
                            <li><strong>Se OBLIGATORIO el sistema de “hombre al agua” para detener el motorcito en caso de accidente </strong></li>
                            <li>Durante todo el trayecto del XXXIV rally TRANSBETXÍ 2024 los motocultores deben estar protegidos con la barra antivuelco. </li>
                            <li>Deben proteger todas las palancas y puntas con bolas o elementos protectores. </li>
                            <li>Hay que recubrir las planchas o chapas con esquinas vivas con perfiles de goma o plásticos.</li>
                            <li><strong>13. En caso de llevar una batería para el funcionamiento de la máquina, el borne positivo deberá ir tapado para evitar entrar en contacto con las partes metálicas del motorcito y poder causar una chispa que provoque un incendio.</strong></ li>
                        </ol>

                        <h4>Art. 04-03.- INSCRIPCIONES</h4>
                        <p>Se permite la inscripción en el XXXIV RALLY TRANSBETXÍ 2024 a toda persona mayor de 18 años, poseedora de licencia deportiva y que haya cumplido la solicitud de inscripción en la prueba, debidamente firmada en el impreso que facilitará el organización y que haya satisfecho los derechos de inscripción.
                            Las inscripciones se inician el 18 de Febrero y concluyen el 16 de Marzo de 2024 a las 21:00 h., y puede realizarse cada día en la página <strong>www.transbetxi.com</strong> .
                        </p>
                        <p>El procedimiento correcto de una inscripción consta de las siguientes partes y deben seguirse en el mismo orden:</p>
                        <ol>
                            <li>Inscripción por parte del equipo del piloto, copiloto y máquina en el impreso que la página www.transbetxi.com dispone para poder efectuarla.</li>
                            <li>Comprobación de la inscripción para el listado oficial de inscritos.</li>
                            <p>Se hará, dentro del plazo de inscripción, remitiendo al correo transbetxi89@gmail.com, debidamente firmada y escaneada, la siguiente documentación:</p>
                            <strong>PILOTO:</strong>
                            <ol>
                                <li>COPIA IMPRESA DE LA INSCRIPCIÓN REALIZADA EN LA PÁGINA WEB.</li>
                                <li>1 FOTOCOPIA Y ORIGINAL DE LA LICENCIA FEDERATIVA (hacia y reverso).</li>
                                <li>1 FOTOCOPIA Y ORIGINAL DEL DOCUMENTO NACIONAL DE IDENTIDAD (hacia y reverso).</li>
                                <li>1 FOTOCOPIA Y ORIGINAL DEL PERMISO DE CONDUCIR: B1 (hacia y reverso).</li>
                            </ol>
                            <strong>COPILOTO:</strong>
                            <ol>
                                <li>COPIA IMPRESA DE LA INSCRIPCIÓN REALIZADA EN LA PÁGINA WEB.</li>
                                <li>1 FOTOCOPIA Y ORIGINAL DE LA LICENCIA FEDERATIVA (hacia y reverso).</li>
                                <li>1 FOTOCOPIA Y ORIGINAL DEL DOCUMENTO NACIONAL DE IDENTIDAD (hacia y reverso).</li>
                                <li>1 FOTOCOPIA Y ORIGINAL DEL PERMISO DE CONDUCIR: B1 (hacia y reverso) O RECONOCIMIENTO MÉDICO EN VIGOR DEL AÑO EN CURSO.</li>
                            </ol>
                        </ol>
                        <p>La organización facilitará a los participantes correctamente inscritos los siguientes documentos:</p>
                        <ol>
                            <li>FICHA DE INSCRIPCIÓN DEL EQUIPO. </li>
                            <li>COPIA DEL REGLAMENTO.</li>
                        </ol>
                        <p>El importe, contemplado en el artículo 10, deberá ser abonado en el momento de la inscripción oficial.
                            En caso de haber modificaciones en la inscripción, el equipo deberá comunicarlo con fecha límite de 24 Marzo de 2024 y adjuntará todos los datos que la organización estime conveniente.
                        </p>
                        <ul>
                            <li>Tf. de contacto 696598253. Ref.: Juanma Moreno.</li>
                            <li>Tf. de contacto 666177845. Ref.: Xavi Cabedo.</li>
                        </ul>
                        <p>Están prohibidas las dobles inscripciones de vehículos y de participantes.</p>

                        <h4>Art. 04-05.- DERECHOS DE INSCRIPCIÓN</h4>
                        <p>Los derechos de inscripción se fijan en <strong>420.-Euros </strong>por vehículo (se incluye el pago de las licencias federativas).
                            La inscripción no podrá ser aceptada si no va acompañada de los derechos de inscripción dentro del plazo fijado en este reglamento.
                        </p>

                        <h4>Art. 04-06.- SEGUROS</h4>
                        <p>La organización se reserva el derecho de completar el reglamento particular y/o emitir disposiciones o instrucciones complementarias, que serán parte integrante. Se reserva también el derecho de anular o parar la prueba en caso de fuerza mayor o suceso imprevisible, sin obligación alguna de indemnización.
                            Todas las modificaciones y disposiciones complementarias serán conocidas por los participantes con la mayor brevedad posible, mediante anexos fechados y numerados y a través del medio que se estime oportuno.
                        </p>
                        <p>Mínimamente todas las modificaciones y disposiciones complementarias serán publicadas en el Tablón Oficial de Avisos.</p>
                        <strong>(Ver: Art. 02-05.- TABLERO DE ANUNCIOS OFICIALES – MODIFICACIONES)</strong>
                        <p>La organización se reserva el derecho de rechazar la inscripción de un equipo; por defectos de forma y plazos, falta de documentación aportada, supuestos contemplados en el presente reglamento y de otras cuestiones relevantes, que puedan atentar contra la integridad, la imagen publica y la seguridad de la carrera.
                            El Jurado está autorizado a decidir sobre cualquier caso no previsto en el Reglamento Particular de la prueba
                        </p>

                        <h3>05.- OBLIGACIONES DE LOS PARTICIPANTES</h3>

                        <h4>Art. 05-01.- ASISTENCIA AL BRIEFING</h4>
                        <p>La asistencia al Briefing inicial del miércoles, día 03 de abril de 2024 a las 22:00 h. en el Auditorio Municipal de Betxí es obligatoria para todos los equipos.
                            A excepción de los casos debidamente justificados y comunicados a la organización, al menos un miembro del equipo (piloto, copiloto o responsable del equipo acreditado) debe asistir, recoger toda la documentación ensalada y quedarse a escuchar la descripción y las advertencias del recorrido de la carrera también se hará entrega de las acreditaciones.
                        </p>

                        <h4>Art. 05-02.- NÚMEROS</h4>
                        <p>Para cada motocultor participante, la organización facilitará, un juego de números doble, que se colocará de forma visible en los laterales del vehículo, durante toda la prueba.
                            Los motocultores que no lleven los números, de los que no sean correctos o no estén correctamente colocados, no serán autorizados a tomar la salida.
                            Los números deben tener, en todo momento, la forma, tamaño y composición original.
                            No se podrán recortar ni añadir ningún adhesivo ni publicidad en ellos
                            La concesión de los números en cada vehículo es competencia de la organización.
                        </p>

                        <h4>Art. 05-03.- PUBLICIDAD</h4>
                        <p>Los vehículos podrán ir publicitados a elección de sus pilotos.
                            Sin embargo, es obligatorio llevar los dorsales proporcionados por la organización.
                            Así, se reservarán dos espacios de 20x20 Cm (uno a cada lado), que permitirán una perfecta visión en los laterales del vehículo.
                            La colocación deberá ser correcta, es decir, adheridos sobre una superficie plana, nunca curva, de soporte metálico liso, totalmente pegado al lateral y sin ningún ángulo saliente.
                            El incumplimiento de este artículo por parte de un equipo participante es considerado como FALTA GRAVE, contemplada en el régimen disciplinario de este reglamento.
                        </p>
                        <h4>Art. 05-04.- PRESENTACIÓN A LA SALIDA </h4>
                        <p>Los participantes y sus vehículos deben presentarse en el Parque Cerrado Pre-cursa y deben estar a disposición de la organización a la hora establecida en el Horario Oficial.
                            Los participantes asumirán las consecuencias de ignorancia de disposiciones o de cambios de horario, que pudieran decidirse durante la hora anterior a la salida de la prueba.
                            Los participantes se colocarán en la fila de salida, al menos, 10 minutos antes de su hora de salida asignada.
                            El motocultor que no se presente a la hora pierde el derecho al orden de salida en la etapa.
                            Por cuestiones de seguridad, este derecho está derogado cuando la fila de salida queda constituida, e incluso, el equipo infractor podría ser excluido de la competición, si así lo considera la organización.
                            El incumplimiento de este artículo por parte de un equipo participante es considerado como FALTA LLEU, contemplada en el régimen disciplinario de este reglamento.
                        </p>

                        <h4>Art. 05-06.- SEÑALIZACIÓN. COMPORTAMIENTO EN LA CARRERA.</h4>
                        <p>Desde su presentación en el Parque Pre-salida hasta la finalización de la prueba, los participantes deberán atender rigurosamente a las indicaciones de la organización.
                            Está totalmente prohibido circular en sentido inverso al de la carrera.
                            Toda infracción a esta norma supondrá la expulsión inmediata de la prueba. El incumplimiento de este artículo por parte de un equipo (participantes o asistencias) es considerado como FALTA MUY GRAVE, contemplada en el régimen disciplinario de este reglamento.
                        </p>
                        <p>En caso de que un piloto deba interrumpir su recorrido, en un tramo cronometrado o en cualquier otro lugar, por problemas mecánicos o de otro tipo, aparcará de inmediato el vehículo fuera de la carretera o pista, porque no suponen ningún problema para el resto de vehículos participantes y efectuará las comprobaciones y reparaciones pertinentes.
                        </p>
                        <p>En caso de no poder reparar el motorcito en el tramo o en el lugar donde se encuentre: </p>
                        <ol>
                            <li>Informar al delegado de seguridad, David Brisach, del estado en que se encuentran y recibir indicaciones de lo que deben hacer. </li>
                            <li>En ningún momento deben abandonar el motorito y el lugar donde estén, hasta que no se lo autorizan el delegado de seguridad. </li>
                        </ol>
                        <strong><small>Cada uno de los vehículos cogidos por detrás por un mes rápido en un tramo cronometrado debe amainar la velocidad. Y dejar paso libre lo antes posible, siempre sin crear ninguna situación peligrosa para nadie y en ningún momento del recorrido podrán desdoblarse recuperando así su posición inicial.</small></strong>
                        <p>El incumplimiento de este artículo por parte de un equipo participante es considerado como FALTA LLEU, contemplada en el régimen disciplinario de este reglamento.
                            No está permitido que ningún vehículo participante sea arrastrado y/o empujado por otro, cualquiera que sea su naturaleza y el sistema de arrastre.
                            El incumplimiento de este artículo por parte de un equipo participante es considerado como FALTA GRAVE, contemplada en el régimen disciplinario de este reglamento.
                        </p>

                        <h3>06.- CONTROL TÉCNICO Y ADMINISTRATIVO</h3>
                        <h4>Art. 06-01.- CONTROL TÉCNICO Y ADMINISTRATIVO</h4>
                        <p>El control técnico y administrativo tendrá lugar en el parque cerrado PTP, viernes 5 de abril de 2024 desde las 18:00 horas, hasta las 20:00 h., en comparecencia gradual de los vehículos, piloto o copiloto, según la programación que la organización hará pública en forma de disposición complementaria en el presente reglamento.
                            El incumplimiento de este artículo por parte de un equipo participante es considerado como FALTA LLEU, contemplada en el régimen disciplinario de este reglamento.
                            Este control se realizará en el vehículo y en los cascos; todos ellos quedarán validados con unos indicativos oficiales, que tendrán que permanecer íntegros a lo largo de toda la carrera. El incumplimiento de este artículo por parte de un equipo participante es considerado como FALTA GRAVE, contemplada en el régimen disciplinario de este reglamento.
                            En este control, se entrega a todos los equipos participantes el Libro de Ruta Oficial de la Cursa (Road Book). Donde tendrán una hoja para poder presentar una reclamación; si lo creen oportuno, siguiendo el procedimiento que marca el reglamento.
                        </p>

                        <h3>CONTROL TÉCNICO Y ADMINISTRATIVO / TURNO DE COMPARECENCIAS</h3>
                        <big>
                            <table>
                                <thead>
                                    <tr>
                                        <th style={{ paddingRight: '10em' }}>HORA</th>
                                        <th>EQUIPOS-DORSAL</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>18:00</td>
                                        <td>53 52 51 50 49 48</td>
                                    </tr>

                                    <tr>
                                        <td>18:15</td>
                                        <td>47	46	45	44	43	42</td>
                                    </tr>

                                    <tr>
                                        <td>18:30</td>
                                        <td>41	40	39	38	37	36</td>
                                    </tr>

                                    <tr>
                                        <td>18:45</td>
                                        <td>35	34	33	32	31	30</td>
                                    </tr>

                                    <tr>
                                        <td>19:00</td>
                                        <td>29	28	27	26	25	24</td>
                                    </tr>

                                    <tr>
                                        <td>19:15</td>
                                        <td>23	22	21	20	19	18</td>
                                    </tr>

                                    <tr>
                                        <td>19:30</td>
                                        <td>17	16	15	14	13	12</td>
                                    </tr>

                                    <tr>
                                        <td>19:45</td>
                                        <td>11	10	9	8	7	6</td>
                                    </tr>
                                    <tr>
                                        <td>20:00</td>
                                        <td>5	4	3	2	1	</td>
                                    </tr>
                                </tbody>
                            </table>
                        </big>

                        <h3>07.- DESARROLLO DE LA PRUEBA</h3>
                        <h4>Art. 07-01.- SALIDA, LLEGADA, CRONOMETRAJE</h4>
                        <p>La salida se dará individualmente con piloto y copiloto en posición (en contacto con el motocultor), con el vehículo parado y el motor en marcha.
                            el orden de salida se da de forma ascendente a la numeración (según su clasificación, por categorías, en el XXXIII TRANSBETXÍ 2023).
                            En caso de no haber terminado el pasado rally o ser nuevo para éste, se le asignará el siguiente número al adjudicado en el último clasificado del anterior rally. Así saldrá el primer participante de series, ya continuación el resto de vehículos de minuto en minuto.
                        </p>
                        <p>Para la etapa del sábado 06 de abril de 2024 el primer clasificado saldrá a las 09:30 horas, ya continuación el resto de clasificados de minuto en minuto.
                            Los participantes están obligados a seguir el recorrido indicado en el Libro de Carrera sin pasarse ningún control.
                            Es necesario respetar los tiempos de descanso previstos por la organización.
                            La organización declina toda responsabilidad en caso de equivocación en el seguimiento de la ruta prevista.
                            El cronometraje se realizará en el segundo, con cronómetros que accionan un sistema de impresión.
                            Ninguna reclamación en contra de la misma será aceptada.
                            Las fichas de tiempo que se facilitan a los equipos sólo son de información y bajo responsabilidad de los participantes.
                            Aquel vehículo que se le haya dado la salida y no la realice de forma inmediata no tiene, en ningún caso, derecho a una segunda salida y se contabiliza a partir del primer tiempo dado.
                            La negación o el retraso superior a 15 segundos al tomar la salida, en cualquier Control Horario o Tramo Cronometrado, significará la exclusión del tramo.
                            Se deben tomar al pie de la letra las instrucciones de la Organización.
                            El incumplimiento de este artículo por parte de un equipo participante es considerado como FALTA LLEU, contemplada en el régimen disciplinario de este reglamento.
                            La llegada en los tramos cronometrados se registrará en el paso del vehículo, con el piloto y el copiloto.
                        </p>
                        <strong>El tramo cronometrado acaba al pasar la línea de llegada y la velocidad debe reducirse inmediatamente, además de no obstaculizar zona de frenado. </strong>
                        <p>El incumplimiento de este artículo por parte de un equipo participante es considerado como FALTA GRAVE, contemplada en el régimen disciplinario de este reglamento.
                            En caso de interrupción forzada de la carrera por cualquier miembro de la organización, a los equipos participantes afectados por esta medida se les dará el tiempo del mejor participante llegado a meta (atendiéndonos al Código Deportivo Internacional y al Concepto de Actitud Antideportiva).
                            Cada tramo cronometrado se cierra después de tantos minutos transcurridos como equipos haya inscritos en la carrera, más cinco minutos suplementarios, después de la hora de salida del primer participante.
                            Cualquier interrupción autorizada por la dirección de la carrera implica un tiempo añadido igual al de la parada.
                        </p>

                        <h4>Art. 07-02.- CARRERA</h4>
                        La carrera tendrá lugar según el horario detallado por la organización en el punto 1 de este reglamento.
                        Habrá 6 tramos cronometrados <strong>(detallados en el artículo 03-01.- RECORRIDO) </strong>con sus etapas de enlace.
                        Cada infracción de las reglas establecidas en el presente reglamento será comunicada al Jurado, y por decisión del mismo, podrá ser motivo de sanción y puede llegar incluso a la exclusión del equipo de la prueba.
                        Toda infracción denunciada por la Guardia Civil, Policía Autonómica, Policía Local o cualquier otro organismo gubernativo implica la exclusión inmediata de la prueba del equipo denunciado.
                        Este supuesto incluye los controles de alcoholemia y otros, que se realizarán en caso de accidente o cuando se estime oportuno.
                        <strong>De la misma forma se trasladará al Comité de Disciplina de la FMCV por si tuvieran algún tipo de sanción por parte de la FMCV.</strong>

                        <h3>08.-CLASIFICACIONES, RECLAMACIONES, APELACIONES</h3>
                        <h4>Art. 08-01.- CLASIFICACIONES </h4>
                        <p>Serán expedidas por la organización como máximo dos horas después de la carrera indicando en ella la hora de publicación o en el caso de una reclamación técnica al estar ésta resuelta.
                            Media hora después de publicada esta clasificación ya queda automáticamente aprobada.
                            En caso de haber en trámite una reclamación de cualquier tipo, no quedará aprobada hasta que no estén todos los casos resueltos y publicada la nueva clasificación.
                        </p>
                        <p>El primer clasificado en un Tramo Cronometrado será el equipo que invierta menos tiempo y así sucesivamente.
                            Los tiempos serán acumulables y se considerará ganador de la prueba el equipo que haya comenzado y terminado todos los tramos cronometrados invirtiendo menos tiempo.
                            En caso de empate, el criterio a seguir será el mayor número de tramos ganados.
                        </p>

                        <h4>Art. 08-02.- RECLAMACIONES</h4>
                        <h4>Art.- 08.02.01 Normas generales</h4>
                        <p>Cada reclamación sólo podrá tratar sobre un punto en concreto.
                            Las reclamaciones serán públicas y personales (el reclamante lo hará en nombre propio)
                            Las reclamaciones colectivas, así como las efectuadas contra las comprobaciones de cronometraje o contra las decisiones de los jueces de hecho, no serán admitidas.
                            Cabe recordar que el derecho de reclamación pertenece sólo a los participantes.
                        </p>
                        <p>Tanto la notificación de la reclamación, como la resolución será comunicada por escrito a las partes interesadas y de continuación publicada en el Tablón Oficial de Avisos.
                            Si la reclamación está fundamentada y se comprueba la anomalía, la fianza y el depósito serán devueltos al reclamante y el reclamado pagará los gastos de la inspección, en caso de haberla.
                            Si la reclamación no está fundamentada y no se encuentra ninguna anomalía, la fianza quedará en poder de la organización y el reclamante pagará los gastos de la inspección, en caso de haberla.
                            El Jurado puede actuar de oficio e iniciar cualquier acción deportiva o de verificación técnica a tantos vehículos y/o participantes como crea conveniente y siempre que encuentre indicios de una irregularidad.
                        </p>
                        <h4>Art.- 08.02.02 Reclamaciones deportivas</h4>
                        <p>Las reclamaciones por temas deportivos tendrán que ser formuladas y presentadas por escrito al Jurado ser acompañadas de una fianza de 100.-Eur.
                            El tiempo máximo para presentar una reclamación es de 30 minutos después de terminada la ultima prueba del día.
                            El Jurado, al considerar fundada y legítima la reclamación, iniciará la apertura de un procedimiento, que finalizará en la redacción de una resolución final, con las conclusiones y posibles sanciones.
                        </p>
                        <h4>Art.- 08.02.03 Reclamaciones contra la clasificación</h4>
                        <p>El plazo de reclamación en contra de los resultados, de la clasificación deportivo es de 30 minutos, después de la publicación de los resultados del día.
                            Deberán ser formuladas y presentadas por escrito al Jurado y acompañarse de una fianza de <strong>100.-Eur</strong> .
                        </p>
                        <h4>Art.- 08.02.04 Reclamaciones técnicas</h4>
                        <p>Las posibles reclamaciones contra una máquina que pudiera presentar componentes que incumpliesen alguna de las especificaciones técnicas, deberá ser formulada y presentada por escrito a la Organización antes de terminar la ultima prueba del día, por escritos y acompañada de una fianza de 100.-Eur., además de un depósito por gastos mecánicos de 100,00.-Eur., pagada por el equipo que formule reclamación. </p>
                        <h4>Art. 08-03.- CONTROL DOPING</h4>
                        <p>Al discurre la prueba por caminos y carreteras públicas las fuerzas de orden público tienen potestad para realizar pruebas que crean conveniente.
                            El hecho de realizar el desplazamiento neutralizado entre tramos, no elimina la obligación de cumplir en esta norma deportiva.
                            Ante un posible accidente en caso de dar positivo en alcohol o algún tipo de droga puede complicarse el pago de la asistencia sanitaria por parte de la aseguradora.
                        </p>
                        <h4>Art. 08-04.-PENALIZACIONES</h4>
                        <p>Se castiga con la penalización a los equipos participantes en los casos y cantidades que se detallan a continuación.
                            El Jurado se reserva el derecho de penalizar otros supuestos, no contemplados en este artículo, con otras cargas
                        </p>
                        <small><strong>ELIMINACIÓN DE LA CLASIFICACIÓN:</strong></small>
                        <ol>
                            <li>Cuando un equipo no inicia un tramo cronometrado.</li>
                            <li>Para los cambios de la máquina participante por otra no inscrita</li>
                        </ol>
                        <small><strong>EXCLUSIÓN DE LA CARRERA:</strong></small>
                        <ol>
                            <li>En los casos de acceso por parte de las asistencias de un equipo a tramos no permitidos por la organización, además de ser castigados con falta grave.</li>
                            <li>En los cambios de piloto o de copiloto por otros no inscritos en la presente edición del XXXIV rally TRASBETXÍ 2024, además de ser castigados con una falta muy grave.</li>
                        </ol>
                        <small><strong>MÁS VEINTE SEGÚN POR SEGUNDO ANTICIPADO:</strong></small>
                        <ol>
                            <li>Para aquellos equipos que, desobedeciendo a los cronometradores oficiales, toman la salida antes de que se la den.</li>
                        </ol>
                        <small><strong>MÁS CINCO SEGÚN:</strong></small>
                        <ol>
                            <li>En los tramos cronometrados TCP (viernes) para los equipos que traspasan o abaten, con una o más ruedas, las señalizaciones que marcan el circuito.</li>
                        </ol>
                        <p>En los tramos TCP y TCD, cuando un vehículo se sale fortuitamente del circuito y no se incorpora por el mismo punto de salida, en los casos involuntarios se penalizará con MÁS QUINCE SEGÚN y en los casos intencionados se penalizará con MÁS UN MINUTO, además de ser castigados con una falta leve.</p>
                        <small><strong>MÁS DOS MINUTOS: </strong></small>
                        <ol>
                            <li>Para aquellos participantes que cuando van a ser doblados no se apartan y facilitan el paso, además de ser castigados con una leve falta.</li>
                        </ol>
                        <small><strong>MÁS UN MINUTO Y EL TIEMPO MÍNIMO ESTABLECIDO POR LA ORGANIZACIÓN:</strong></small>
                        <ol>
                            <li>Por aquellos equipos que por diversas razones (mecánicas, físicas, etc.), en un tramo cronometrado, se les dé la salida y no puedan conseguir llegar a meta, cualquiera que sea la distancia recorrida. Sin embargo, igualmente tienen derecho a continuar en competición para las mejores clasificaciones absolutas.</li>
                        </ol>
                        <p>En los casos donde no lleguen a meta en el mismo tiempo piloto y copiloto, será anotado, como tiempo realizado, el marcado por el último componente del equipo que llegue a meta; en los casos en que uno de los dos no termine el tramo cronometrado, se aplicará el punto anterior</p>
                        <small><strong>MAS DIEZ MINUTOS:</strong></small>
                        <ol>
                            <li>El cambio de cilindro por avería. </li>
                            <li>El cilindro de repuesto deberá haber sido verificado y precintado por la organización, en el control administrativo</li>
                        </ol>
                        <small><strong>MAS VEINTE MINUTOS:</strong></small>
                        <ol>
                            <li>Por pérdida del precinto oficial y no comunicarlo a la organización.</li>
                        </ol>
                        <h4>Art. 08-04 RÉGIMEN DISCIPLINARIO </h4>
                        <p>Se castiga con la aplicación de este régimen disciplinario a los equipos participantes en los casos y las consecuencias que se detallan a continuación.
                            El Jurado se reserva el derecho a penalizar otros supuestos no contemplados en este artículo con otras cargas.
                        </p>
                        <p>Una <strong>FALTA MUY GRAVE</strong> supone la exclusión de la carrera, la pérdida de la fianza pagada y la imposibilidad de ser inscrito por la edición del rally Transbetxí del año siguiente a cualquiera de los miembros del equipo.</p>
                        <p>Una <strong>FALTA GRAVE</strong> supone la exclusión de la carrera y la pérdida de la fianza pagada. </p>
                        <p>La acumulación de cinco <strong>FALTAS LEVES</strong> implica la comisión de una falta grave.</p>
                        <p>Otros supuestos no contemplados en el presente reglamento particular, quedan sometidos al régimen disciplinario de la FMCV</p>

                        <h3>09.-PREMIOS Y TROFEOS, ENTREGA DE PREMIOS</h3>
                        <h4>Art. 09-01.-PREMIOS</h4>
                        <p>Los premios serán:</p>
                        <ol>
                            <li>Al primer Clasificado Absoluto (menos tiempo invertido al final de todas las etapas cronometradas).</li>
                            <li>En el segundo Clasificado Absoluto (id.).</li>
                            <li>En el tercer Clasificado Absoluto (id.).</li>
                            <li>A los 3 primeros clasificados de cada categoría</li>
                        </ol>
                        <p>La entrega de premios se hará el Domingo 07 de Abril a las 18:30 h. en el FRENTE MUNICIPAL.</p>
                    </div> */}
                </Grid>
            </Grid>

            <PiePagina></PiePagina>
        </div>
    )
}
