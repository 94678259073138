import React from 'react';
import { SelectorIdioma } from '../components/SelectorIdioma';
import { InscripcionesEs } from '../landing_page/inscripciones/InscripcionesEs';
import { InscripcionesVal } from '../landing_page/inscripciones/InscripcionesVal';
import { InscripcionesEn } from '../landing_page/inscripciones/InscripcionesEn';
import { InscripcionesGer } from '../landing_page/inscripciones/InscripcionesGer';
import { useMyContext } from '../MyProvider '; //Variable global del idioma
import { Inscritos } from '../landing_page/inscripciones/Inscritos.jsx';
import { InscritosEs } from '../landing_page/inscritos/InscritosEs.jsx';
import { InscritosVal } from '../landing_page/inscritos/InscritosVal.jsx';
import { InscritosGer } from '../landing_page/inscritos/InscritosGer.jsx';
import { InscritosEn } from '../landing_page/inscritos/InscritosEn.jsx';
import { Header } from '../components/Header.jsx';
import { HeaderVal } from '../components/HeaderVal.jsx';
import { HeaderEn } from '../components/HeaderEn.jsx';
import { HeaderGer } from '../components/HeaderGer.jsx';
import { PiePagina } from '../components/PiePagina.jsx';

export const Inscripciones = () => {

  const { miVariableGlobal, setMiVariableGlobal } = useMyContext();

  return (
    <div>
      <SelectorIdioma idioma={miVariableGlobal}></SelectorIdioma>

      {(miVariableGlobal == 1) && (
         <InscripcionesEs></InscripcionesEs>
        // <InscritosEs />
        // <Header />
      )}

      {(miVariableGlobal == 2) && (
        <InscripcionesVal></InscripcionesVal>
        // <InscritosVal />
        // <HeaderVal />
      )}

      {(miVariableGlobal == 3) && (
        <InscripcionesEn></InscripcionesEn>
        // <InscritosEn />
        // <HeaderEn />
      )}

      {(miVariableGlobal == 4) && (
        <InscripcionesGer></InscripcionesGer>
        // <InscritosGer />
        // <HeaderGer />
      )}
      {/* <PiePagina></PiePagina> */}
    </div>
  )
}
