import React, { useEffect, useRef, useState } from "react";
import { getCookie } from "../../utils/cookies";
import { getoCntrEquipos } from "../../apis";
import { Grid } from "@mui/material";

const WEBSOCKET_URL = "wss://websockets.transbetxi.com:8443";

export const ControlEquipos = () => {
	const ws = useRef(null);
	const [users, setUsers] = useState({}); // WebSocket en tiempo real
	const [localizadores, setLocalizadores] = useState([]); // Último registro por equipo
	const token = getCookie('PHPSESSID');

	useEffect(() => {
		ws.current = new WebSocket(`${WEBSOCKET_URL}?token=${token}`);

		ws.current.onopen = () => {
			console.log("✅ Conectado al WebSocket como observador");
		};

		ws.current.onmessage = (event) => {
			try {
				const data = JSON.parse(event.data);

				if (data.from && data.datos) {
					const { from, id, datos } = data;

					setUsers((prev) => ({
						...prev,
						[id]: {
							nombre: from,
							coords: datos,
							lastUpdate: new Date(),
						},
					}));
				}
			} catch (err) {
				console.error("❌ Error procesando mensaje:", err);
			}
		};

		ws.current.onerror = (err) => {
			console.error("❌ Error en WebSocket:", err);
		};

		ws.current.onclose = () => {
			console.warn("🔌 Conexión WebSocket cerrada");
		};

		return () => {
			if (ws.current) ws.current.close();
		};
	}, []);

	const cargarLocalizadoresLogeados = async () => {
		try {
			const response = await getoCntrEquipos();

			// Agrupar por nombre_equipo y quedarnos con el más reciente
			const ultimos = {};
			response.forEach((item) => {
				const nombre = item.nombre_equipo;
				if (
					!ultimos[nombre] ||
					new Date(item.fecha) > new Date(ultimos[nombre].fecha)
				) {
					ultimos[nombre] = item;
				}
			});

			setLocalizadores(Object.values(ultimos));
		} catch (error) {
			console.error("Error al cargar los localizadores:", error);
		}
	};

	useEffect(() => {
		cargarLocalizadoresLogeados();
	}, []);

	return (
		<div style={{ padding: 20 }}>
			<h2>🛰 Usuarios Enviando Ubicación (Tiempo Real)</h2>
			{Object.keys(users).length === 0 ? (
				<p>No hay datos aún.</p>
			) : (
				<ul>
					{Object.entries(users).map(([id, user]) => (
						<li key={id} style={{ marginBottom: 10 }}>
							<strong>{user.nombre}</strong> (ID: {id})<br />
							Lat: {user.coords.latitude.toFixed(5)} | Lon: {user.coords.longitude.toFixed(5)}<br />
							<span style={{ color: "green" }}>
								Última actualización: {user.lastUpdate.toLocaleTimeString()}
							</span>
						</li>
					))}
				</ul>
			)}

			<h2>📋 Última Conexión por Equipo (Histórico)</h2>
			{localizadores.length === 0 ? (
				<p>No hay registros históricos.</p>
			) : (
				<Grid container spacing={2}>
					{localizadores.map((localizador) => (
						<Grid item xs={12} sm={6} md={4} key={localizador.id}>
							<div style={{ border: "1px solid #ccc", padding: 10, borderRadius: 8 }}>
								<strong>Equipo:</strong> {localizador.nombre_equipo}<br />
								<strong>Usuario ID:</strong> {localizador.id_usuario_app}<br />
								<strong>Última conexión:</strong> {new Date(localizador.fecha).toLocaleString()}<br />
								<strong>Activo:</strong> {localizador.activo == 1 ? "Sí" : "No"}<br />

							</div>
						</Grid>
					))}
				</Grid>
			)}
		</div>
	);
};
